import { IonCol, IonGrid, IonImg, IonRouterLink, IonRow, IonText, IonTitle } from '@ionic/react';
import React from 'react';

export const TutorialSlide: React.FC<{
	title: string;
	content: string;
	image?: string;
	isLastSlide?: boolean;
}> = ({ title, content, image, isLastSlide }) => {
	return (
		<IonGrid>
			<IonRow className="ion-justify-content-center">
				<IonCol sizeXs="10" sizeSm="9" sizeMd="8" sizeLg="4" sizeXl="3" className="pd-tutorial-img">
					<IonImg style={{ pointerEvents: 'none' }} src={image} alt="tutorial" />
				</IonCol>
				<IonCol size="12">
					<IonGrid className="ion-padding">
						<b>{title}</b>
					</IonGrid>
				</IonCol>
				<IonCol size="9">
					<IonTitle size="small">{content}</IonTitle>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol className="pd-tutorial-skip">
					<IonText className="ion-padding">
						<IonRouterLink routerLink="/welcome" routerDirection="none">
							{isLastSlide ? <b>Continuar</b> : <b>Saltar</b>}
						</IonRouterLink>
					</IonText>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
