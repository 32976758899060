import { IonGrid } from '@ionic/react';
import React from 'react';

export const ErrorMessageForm: React.FC<{ message?: string; id: string }> = ({ message, id }) => {
	return (
		<IonGrid id={id} className="ion-text-start" style={{ color: 'red', fontSize: 'smaller' }}>
			{message}
		</IonGrid>
	);
};
