import { IonButton, IonCol, IonContent, IonFooter, IonList, IonProgressBar, IonRouterLink, IonRow } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormInput } from '../components/FormInput';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import { IonFullscreenPage } from '../helpers/Fullscreen';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { Config } from '../utils/config';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('LeaveBookPage');

const LeaveBookPage: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();
	const bookId = +useParams<{ bookId: string }>().bookId;
	const authorizationId = +useParams<{ authorizationId: string }>().authorizationId;
	const [userPin, setUserPin] = useState<string | null>();
	const { handleSubmit, control, errors } = useForm();
	const [currentTimer, setCurrentTimer] = useState<number>();
	let counter = 30;

	const backToBook = useCallback(async () => {
		history.replace(`/books/read/${authorizationId}/${bookId}`);
	}, [authorizationId, bookId, history]);

	useEffect(() => {
		const subs = [
			middleware.user.currentUser$.subscribe((user) => setUserPin(user?.pin)),

			timer(0, 1000)
				.pipe(tap(() => counter--))
				.subscribe(() => {
					if (counter === 0) {
						backToBook();
					}
					setCurrentTimer(counter);
				}),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [middleware.user.currentUser$, counter, backToBook]);

	const handleLeaveBook = (data: any) => {
		logger.verbose('HV ~ handleLeaveBook ~ data.pin', data.pin);
		if (data.pin !== userPin) {
			notify.showCustomNotify('danger', '¡Pin incorrecto!');
		} else {
			history.replace(`/menu/books/detail/${bookId}`);
		}
	};

	return (
		<IonFullscreenPage className="ion-text-center">
			<HeaderApp goBackTo={`/books/read/${authorizationId}/${bookId}`} />
			<IonContent className="ion-padding ion-text-center" fullscreen>
				{notify.notifyProps.show && <ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />}
				<h2>
					<span>
						Ingresa el <b>PIN</b>{' '}
					</span>
					{Config.UI_DEBUG_ENTITY_IDS && <span>({userPin}) </span>}
					<span>para salir:</span>
				</h2>
				<IonList className="ion-padding">
					<form onSubmit={handleSubmit(handleLeaveBook)}>
						<FormInput
							label=""
							name="pin"
							control={control}
							errors={errors}
							type="number"
							rules={{
								required: '¡Debe ingresar un valor!',
								pattern: {
									value: /^[0-9]+$/,
									message: '¡Debe ingresar solo números!',
								},
								minLength: {
									value: 4,
									message: '¡PIN debe tener mínimo 4 caracteres!',
								},
								maxLength: {
									value: 6,
									message: '¡PIN debe tener máximo 6 caracteres!',
								},
							}}
						/>

						<IonRow className="ion-padding">
							<IonCol>
								<IonButton className="pd-button-primary" type="submit">
									Salir
								</IonButton>
							</IonCol>
						</IonRow>
					</form>
				</IonList>

				<IonRouterLink routerLink={`/recovery-pin/${bookId}/${authorizationId}`} routerDirection="none">
					<span id="btn-forgot-pin">¿Olvidaste tu PIN?</span>
				</IonRouterLink>
			</IonContent>

			<IonFooter>
				<div style={{ backgroundColor: '#fffaeb', boxShadow: 'none' }} className="ion-padding">
					<IonProgressBar color="primary" value={currentTimer! / counter} reversed={true} />
				</div>
			</IonFooter>
		</IonFullscreenPage>
	);
};

export default withTransaction('LeaveBookPage', 'component')(LeaveBookPage);
