import { IonIcon } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IBookModel } from '../models/IBookModel';
import { Config } from '../utils/config';
import styles from './BookGrid.module.scss';
import { checkmarkCircle } from 'ionicons/icons';
import { BookCover } from './BookCover';
import { useIsMounted } from '../hooks/useIsMounted';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const BookCard: React.FC<{
	book: IBookModel;
	middleware: AppMiddleware;
	onClick?: (book: IBookModel) => void;
}> = ({ book, middleware, onClick }) => {
	const [cached, setCached] = useState<boolean>(false);
	const isMounted = useIsMounted();

	console.log('En bookCard Component');

	useEffect(() => {
		if (book && book.coverImage) {
			middleware.books.isCached(book).then((res) => {
				if (isMounted.current) setCached(res);
			});
		} else {
			if (isMounted.current) setCached(false);
		}
	}, [book, middleware.assets, middleware.books, isMounted]);

	return (
		<div
			id={`book-id-${book.id}`}
			className={`${styles.BookCard} ${cached ? styles.Cached : ''}`}
			onClick={() => onClick?.(book)}
		>
			<div className={styles.BookCardCover}>
				<BookCover book={book} middleware={middleware} />
				{cached && <IonIcon className={styles.CachedIcon} icon={checkmarkCircle} />}
			</div>
			<div className={styles.BookCardTitle}>
				{Config.UI_DEBUG_ENTITY_IDS && <span>[#{book.id}] </span>}
				{book.title}
			</div>
			<div className={styles.BookCardSubtitle}>{book.subtitle}</div>
		</div>
	);
};

export default withTransaction('BookCard', 'component')(BookCard);
