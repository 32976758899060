import { ErrorMessage } from '@hookform/error-message';
import { IonButton, IonCol, IonDatetime, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow } from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { Control, Controller, DeepMap, FieldError, RegisterOptions } from 'react-hook-form';
import { ErrorMessageForm } from './ErrorMessageForm';

export const FormInput: React.FC<{
	control: Control;
	label: string;
	name: string;
	errors: DeepMap<Record<string, any>, FieldError>;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	type?:
		| 'number'
		| 'time'
		| 'text'
		| 'tel'
		| 'url'
		| 'email'
		| 'search'
		| 'date'
		| 'password'
		| 'week'
		| 'month'
		| 'datetime-local';
}> = (props) => {
	const [showPassword, setShowPassword] = useState<boolean>(false);

	return (
		<IonGrid>
			<IonRow className="ion-justify-content-center">
				<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="4">
					<IonItem lines="inset">
						<IonLabel className="ion-padding-bottom" position="floating">
							{props.label}
						</IonLabel>
						<Controller
							render={({ onChange, value }) =>
								props.type === 'date' ? (
									<IonDatetime
										doneText="Aceptar"
										cancelText="Cancelar"
										displayFormat="DD-MM-YYYY"
										monthShortNames="ene, feb, mar, abr, may, jun, jul, ago, sep, oct, nov, dic"
										value={value || null}
										onIonChange={onChange}
									/>
								) : (
									<div className="pd-form-input">
										<IonInput
											id={props.name}
											type={
												!props.type || props.type === 'number'
													? 'text'
													: props.type === 'password' && showPassword
													? 'text'
													: props.type
											}
											value={value}
											inputmode={props.type === 'number' ? 'numeric' : 'text'}
											onIonChange={onChange}
										/>

										{value && props.type === 'password' && (
											<IonButton
												color="transparent"
												className="pd-icon-password"
												onClick={() => setShowPassword(!showPassword)}
											>
												<IonIcon color="dark" icon={showPassword ? eyeOffOutline : eyeOutline} />
											</IonButton>
										)}
									</div>
								)
							}
							control={props.control}
							name={props.name}
							rules={props.rules}
						/>
					</IonItem>
					<ErrorMessage
						errors={props.errors}
						name={props.name}
						render={({ message }) => <ErrorMessageForm id={`error_${props.name}`} message={message} />}
					/>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
