import { IonCard, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonText, IonThumbnail } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useIsMounted } from '../hooks/useIsMounted';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IBookModel } from '../models/IBookModel';
import { IDeepCategoryModel } from '../models/ICategoryModel';
import { BookCover } from './BookCover';

export const CatalogMainView: React.FC<{
	middleware: AppMiddleware;
	categories: IDeepCategoryModel[];
	onSelectedBook: (bookId: number) => void;
	onSelectedCategory: (categoryId: number) => void;
}> = ({ middleware, onSelectedBook, onSelectedCategory, categories }) => {
	return (
		<IonGrid className="ion-justify-content-center">
			{categories && categories.length > 0 ? (
				<IonRow className="ion-padding-horizontal">
					<IonCol size="12">
						<IonText>
							<h4 className="ion-text-start">
								<b>
									<span id="page-title" className="pd-main-page-title">
										Catálogo
									</span>
								</b>
							</h4>
						</IonText>
					</IonCol>
					{categories.map((category) => {
						return (
							<CategoryCard
								key={category.id}
								category={category}
								middleware={middleware}
								onSelectedCategory={onSelectedCategory}
							/>
						);
					})}
				</IonRow>
			) : (
				<IonGrid className="ion-text-center ion-padding">
					<span id="result-message">No hay resultados</span>
				</IonGrid>
			)}

			{categories && categories.length > 0 && (
				<IonRow>
					<IonCol size="12">
						<IonText className="ion-text-left">
							<h4 className="ion-padding-horizontal">
								<span>Cuadernos</span>
							</h4>
						</IonText>
					</IonCol>
					{categories.map((category) => {
						return (
							category.books &&
							category.books.length > 0 &&
							category.books
								.filter((book) => book.available)
								.map((book: IBookModel) => {
									return (
										<IonCol key={book.id} sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" sizeXl="3">
											<MiniBookCard book={book} middleware={middleware} onClick={onSelectedBook} />
										</IonCol>
									);
								})
						);
					})}
				</IonRow>
			)}
		</IonGrid>
	);
};

const MiniBookCard: React.FC<{
	book: IBookModel;
	middleware: AppMiddleware;
	onClick?: (bookId: number) => void;
}> = ({ book, middleware, onClick }) => {
	return (
		<IonItem
			mode="md"
			id={`book-id-${book.id}`}
			className="ion-align-items-start"
			button
			lines="none"
			onClick={() => onClick?.(book.id)}
		>
			<IonThumbnail className="pd-book-list-thumbnail">
				<div className="pd-book-cover-shadow">
					<BookCover book={book} middleware={middleware} />
				</div>
			</IonThumbnail>
			<IonLabel className="ion-padding-horizontal">
				<h2 className="pd-category-book-title">{book.title}</h2>
				<h4 className="pd-category-book-subtitle">{book.subtitle}</h4>
			</IonLabel>
		</IonItem>
	);
};

const CategoryCard: React.FC<{
	category: IDeepCategoryModel;
	middleware: AppMiddleware;
	onSelectedCategory?: (categoryId: number) => void;
}> = ({ category, middleware, onSelectedCategory }) => {
	const [listImage, setListImage] = useState<string | null>();
	const isMounted = useIsMounted();

	useEffect(() => {
		if (category.listImage) {
			middleware.assets.getImage(category.listImage).then((img) => {
				if (isMounted.current) setListImage(img);
			});
		} else {
			if (isMounted.current) setListImage(null);
		}
	}, [category, middleware.assets, isMounted]);

	return (
		<IonCol sizeXs="6" sizeSm="4" sizeMd="3" sizeLg="3" sizeXl="2">
			<IonCard
				id={`category-id-${category.id}`}
				className="pd-category-card"
				style={{ background: category.color }}
				onClick={() => (onSelectedCategory ? onSelectedCategory(category.id) : null)}
			>
				<div className="card-main-title ion-no-wrap">{category.title}</div>
				<div className="card-main-subtitle">{category.subtitle}</div>

				<img alt="category" className="card-img" src={listImage || ''} />
			</IonCard>
		</IonCol>
	);
};
