import { Logger } from "./logging";

const logger = new Logger('IDBWrapper');

// Simple wrapper for IndexedDB
export class IDBWrapper {
	// We store the promise so that multiple calls to getDb before the
	// first one resolves do not reopen the db
	private readonly _dbPromise: Promise<IDBDatabase>;

	constructor(private name: string) {
		this._dbPromise = new Promise<IDBDatabase>((resolve, reject) => {
			const req = indexedDB.open(name);
			req.onerror = () => {
				logger.error('Could not open IndexedDB', name, req.error);
				reject(req.error);
			};
			req.onsuccess = () => {
				// TODO: check that version is correct, see https://javascript.info/indexeddb
				const db = req.result;
				db.onversionchange = () => {
					db!.close();
					alert('Database is outdated, please reload the page.');
				};
				resolve(db);
			};
			req.onupgradeneeded = (event) => {
				logger.info('Opened IndexedDB, upgrade needed', name);
				if (event.oldVersion !== 0) {
					// TODO: Handle upgrade requests
					reject('Upgrade required, not handled yet');
					throw new Error('Upgrade required, not handled yet');
				}

				const db = req.result;
				db.createObjectStore('_');
			};
		});
	}

	private async getDb(): Promise<IDBDatabase> {
		return this._dbPromise;
	}

	private async runInTx(
		mode: 'readonly' | 'readwrite' | 'versionchange' | undefined,
		f: (store: IDBObjectStore) => IDBRequest
	) {
		const db = await this.getDb();

		const tx = db.transaction('_', mode);
		const store = tx.objectStore('_');

		const req = f(store);

		return new Promise<any>((resolve, reject) => {
			req.onsuccess = () => resolve(req.result);
			req.onerror = () => reject(req.error);
		});
	}

	async put(key: string, value: any) {
		return this.runInTx('readwrite', (store) => store.put(value, key));
	}

	async delete(key: string) {
		return this.runInTx('readwrite', (store) => store.delete(key));
	}

	async get(key: string): Promise<any> {
		return this.runInTx('readonly', (store) => store.get(key));
	}

	async getAll(
		query?: string | number | Date | ArrayBufferView | ArrayBuffer | IDBArrayKey | IDBKeyRange | null | undefined,
		count?: number | undefined
	): Promise<any> {
		return this.runInTx('readonly', (store) => store.getAll(query, count));
	}
}
