import { IonImg, IonSkeletonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useIsMounted } from '../hooks/useIsMounted';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IBookModel } from '../models/IBookModel';
import styles from './BookCover.module.scss';

export const BookCover: React.FC<{
	book: IBookModel;
	middleware: AppMiddleware;
	onClick?: (book: IBookModel) => void;
}> = ({ book, middleware, onClick }) => {
	const [coverImage, setCoverImage] = useState<string | null>();
	const isMounted = useIsMounted();

	useEffect(() => {
		if (book.coverImage) {
			middleware.assets.getImage(book.coverImage).then((img) => {
				if (isMounted.current) setCoverImage(img);
			});
		} else {
			if (isMounted.current) setCoverImage(null);
		}
	}, [book, middleware.assets, middleware.books, isMounted]);

	return (
		<div onClick={() => onClick?.(book)}>
			<div
				className={styles.BookCover}
				style={{
					// Aspect ratio trick, see https://css-tricks.com/aspect-ratio-boxes/
					// This allows to define the width in css, with media queries
					// We need to keep this div inside another div of the same width
					// for this to work.
					height: 0,
					paddingTop: `${(100 * book.heightMM) / book.widthMM}%`,
				}}
			>
				{coverImage ? <IonImg id={`book-cover-${book.id}`} src={coverImage} /> : <IonSkeletonText animated />}
			</div>
		</div>
	);
};
