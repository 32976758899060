/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router';
import { PrivateRoute } from './helpers/PrivateRoute';
import { AppMiddleware } from './middleware/AppMiddleware';
import AccountPage from './pages/AccountPage';
import AcquisitionsPage from './pages/AcquisitionsPage';
import ActivateAccountPage from './pages/ActivateAccountPage';
import ActivateBookPage from './pages/ActivateBookPage';
import BookDetailPage from './pages/BookDetailPage';
import BookPage from './pages/BookPage';
import BookWithoutGrant from './pages/BookWithoutGrantPage';
import CatalogPage from './pages/CatalogPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import LeaveBookPage from './pages/LeaveBookPage';
import LoginPage from './pages/LoginPage';
import PrivacyPoliciesPage from './pages/PrivacyPoliciesPage';
import ProfileActionPage from './pages/ProfileActionPage';
import ProfilesBookPage from './pages/ProfilesBookPage';
import ProfilePage from './pages/ProfilesPage';
import RecoveryPasswordPage from './pages/RecoveryPasswordPage';
import RecoveryPinPage from './pages/RecoveryPinPage';
import RedirectPage from './pages/RedirectPage';
import SettingsPage from './pages/SettingsPage';
import SignupPage from './pages/SignupPage';
import TutorialPage from './pages/TutorialPage';
import WelcomePage from './pages/WelcomePage';
import './theme/theme.scss';
/* Theme variables */
import './theme/variables.css';
import { Config } from './utils/config';
import { Logger } from './utils/logging';

const logger = new Logger('Routes');

export const Routes: React.FC<{
	middleware: AppMiddleware;
}> = ({ middleware }) => {
	const [authenticated, setAuthenticated] = React.useState<boolean | undefined>(undefined);
	const [lastPathName, setLastPathName] = React.useState<string>('');
	const location = useLocation();

	useEffect(() => {
		logger.verbose('init');

		const lastPathname = localStorage.getItem('pathname') || Config.UI_START_PAGE;
		setLastPathName(lastPathname);
		logger.info('lastPathname', lastPathname);
	}, []);

	useEffect(() => {
		// Puente entre el observable del middleware y React
		const subs = [
			middleware.user.currentUser$.subscribe((user) => {
				logger.info('currentUser', user);
				setAuthenticated(!!user);
			}),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [middleware.user.currentUser$, setAuthenticated]);

	// Cada vez que se cambia de 'location.pathname' se verifica la autenticación
	useEffect(() => {
		logger.info('location', location);
		middleware.user.checkAuth();
		if (location.pathname !== Config.UI_LOGIN_PAGE) {
			localStorage.setItem('pathname', location.pathname);
		}
	}, [middleware.user, location]);

	return (
		<>
			{/* Tabs */}
			<Route exact path="/menu/:tab(store)" render={() => <CatalogPage middleware={middleware} />} />
			<Route exact path="/menu/:tab(library)" render={() => <AcquisitionsPage middleware={middleware} />} />
			<Route exact path="/menu/:tab(setting)" render={() => <SettingsPage middleware={middleware} />} />

			{/* Intro */}
			<Route exact path="/" render={() => <RedirectPage path={lastPathName} />} />
			<Route exact path="/tutorial" render={() => <TutorialPage />} />

			{/* Auth */}
			<Route exact path="/login" render={() => <LoginPage middleware={middleware} />} />
			<Route exact path="/signup" render={() => <SignupPage middleware={middleware} />} />
			<Route exact path="/recovery-password" render={() => <RecoveryPasswordPage middleware={middleware} />} />
			<Route exact path="/change-password" render={() => <ChangePasswordPage middleware={middleware} />} />
			<Route exact path="/activate-account" render={() => <ActivateAccountPage middleware={middleware} />} />
			<Route exact path="/policies" component={PrivacyPoliciesPage} />

			{/* Start App */}
			<Route exact path="/welcome" render={() => <WelcomePage middleware={middleware} />} />

			{/* Setting */}
			<PrivateRoute isAuth={authenticated} path="/menu/profiles/list/:acquisitionId?">
				<ProfilePage middleware={middleware} />
			</PrivateRoute>

			<PrivateRoute isAuth={authenticated} path="/menu/profiles/action/:profileAction/:acquisitionId?/:profileId?">
				<ProfileActionPage middleware={middleware} />
			</PrivateRoute>

			<PrivateRoute isAuth={authenticated} path="/menu/account">
				<AccountPage middleware={middleware} />
			</PrivateRoute>

			<Route
				exact
				path="/recovery-pin/:bookId/:authorizationId"
				render={() => <RecoveryPinPage middleware={middleware} />}
			/>

			{/* Books */}
			<Route
				exact
				path="/menu/books/detail/:bookId/:categoryId?"
				render={() => <BookDetailPage middleware={middleware} />}
			/>

			<PrivateRoute isAuth={authenticated} path="/menu/books/profiles/:acquisitionId">
				<ProfilesBookPage middleware={middleware} />
			</PrivateRoute>

			<PrivateRoute isAuth={authenticated} path="/menu/books/activate/:bookId?">
				<ActivateBookPage middleware={middleware} />
			</PrivateRoute>

			{/* Book */}
			<PrivateRoute isAuth={authenticated} exact path="/books/leave/:bookId/:authorizationId">
				<LeaveBookPage middleware={middleware} />
			</PrivateRoute>

			<PrivateRoute isAuth={authenticated} exact path="/books/withoutgrant/:bookId/:authorizationId">
				<BookWithoutGrant middleware={middleware} />
			</PrivateRoute>

			<PrivateRoute isAuth={authenticated} exact path="/books/read/:authorizationId/:bookId">
				<BookPage middleware={middleware} />
			</PrivateRoute>

			{/* Google */}
			<Route
				exact
				path="/my-account/google"
				render={() => {
					const url = new URL(window.location.href);
					const token = url.searchParams.get('token') as string;

					localStorage.setItem('token', encodeURIComponent(token));
					window.location.href = '/welcome';
					return null;
				}}
			/>
		</>
	);
};
