import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { BookCover } from '../components/BookCover';
import { HeaderApp } from '../components/HeaderApp';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IBookModel } from '../models/IBookModel';
import { ICategoryModel } from '../models/ICategoryModel';

export const CatalogCategoryView: React.FC<{
	categoryId: number;
	middleware: AppMiddleware;
	onSelectedBook: (bookId: number) => void;
	onBack: () => void;
}> = ({ middleware, categoryId, onSelectedBook, onBack }) => {
	const [books, setBooks] = useState<IBookModel[]>([]);
	const [category, setCategory] = useState<ICategoryModel | null>(null);
	const [mainImage, setMainImage] = useState<string | undefined>();

	useEffect(() => {
		const subs = [
			middleware.categories.byId$(categoryId).subscribe((cat) => {
				setCategory(cat);
			}),
			middleware.books.all$.subscribe((books) => {
				setBooks(books.filter((book) => book.categoryId === categoryId && book.available));
			}),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [middleware.categories, middleware.books, categoryId]);

	useEffect(() => {
		if (category?.mainImage) {
			middleware.assets.getImage(category.mainImage).then((image) => setMainImage(image || undefined));
		} else {
			setMainImage(undefined);
		}
	}, [category, middleware.assets]);

	return (
		<IonPage id="page-category">
			<HeaderApp
				categoryStyle={true}
				categoryAvatar={mainImage}
				title={`${category?.title}`}
				background={category?.color}
				onBack={onBack}
				autoHide={false}
			/>
			<IonContent className="ion-padding">
				<IonGrid className="ion-padding-vertical">
					<IonRow className="ion-justify-content-center">
						<IonCol className="ion-padding" sizeLg="10" sizeXl="8">
							<IonRow className="ion-justify-content-start">
								{books && books.length ? (
									books.map((book: IBookModel) => {
										return (
											<IonCol
												id={`book-id-${book.id}`}
												key={book.id}
												onClick={() => onSelectedBook(book.id)}
												className="ion-padding"
												sizeXs="6"
												sizeSm="4"
												sizeMd="3"
												sizeLg="2.5"
												sizeXl="2"
											>
												<div className="pd-book-cover-shadow">
													<BookCover book={book} middleware={middleware} />
												</div>
												<div className="pd-category-book-title ion-padding-top">{book.title}</div>
												<div className="pd-category-book-subtitle">{book.subtitle}</div>
											</IonCol>
										);
									})
								) : (
									<IonGrid>
										<span id="result-message">No hay cuadernos disponibles en esta categoría</span>
									</IonGrid>
								)}
							</IonRow>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};
