import { getImageAsArrayBuffer } from './request';
import { Logger } from "./logging";

const logger = new Logger('crypto');

export abstract class AbstractDecrypter {
	abstract decrypt(data: ArrayBuffer): Promise<ArrayBuffer>;

	static get(keystr: string): AbstractDecrypter {
		logger.info('Checking WebCrypto availability', crypto);
		if (crypto && crypto.subtle) return new WebCryptoDecrypter(keystr);
		else return new NoCryptoDecrypter(keystr);
	}
}

class WebCryptoDecrypter extends AbstractDecrypter {
	private key: CryptoKey | null = null;

	constructor(keystr: string) {
		super();
		crypto.subtle
			.importKey(
				'jwk',
				{
					kty: 'oct',
					k: keystr,
					alg: 'A256CBC',
					ext: true,
				},
				{
					name: 'AES-CBC',
					length: 256,
				},
				false,
				['encrypt', 'decrypt']
			)
			.then((key) => {
				this.key = key;
			});
	}

	async decrypt(data: ArrayBuffer): Promise<ArrayBuffer> {
		return crypto.subtle.decrypt(
			{
				name: 'AES-CBC',
				iv: new Uint8Array(16),
			},
			this.key!,
			data
		);
	}
}

class NoCryptoDecrypter extends AbstractDecrypter {
	private imgPromise: Promise<ArrayBuffer>;

	constructor(keystr: string) {
		super();
		this.imgPromise = getImageAsArrayBuffer('/assets/icon/icon.png');
	}

	async decrypt(data: ArrayBuffer): Promise<ArrayBuffer> {
		return this.imgPromise;
	}
}
