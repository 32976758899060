import { IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonPage, IonRouterLink, IonRow, IonText } from '@ionic/react';
import { add } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import BookCard from '../components/BookCard';
import { BookGrid } from '../components/BookGrid';
import { HeaderApp } from '../components/HeaderApp';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IAcquisitionModel } from '../models/IAcquisitionModel';
import { IBookModel } from '../models/IBookModel';
import { IUserModel } from '../models/IUserModel';
import { Config } from '../utils/config';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const AcquisitionsPage: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const history = useHistory();
	const [books, setBooks] = useState<IBookModel[]>([]);
	const [currentUser, setCurrentUser] = useState<IUserModel | null>(null);

	useEffect(() => {
		const getBooks$ = combineLatest([middleware.books.all$, middleware.acquisitions.all$]).pipe(
			map(([books, acqs]) => {
				return books.filter((book: IBookModel) => {
					return acqs.some((acq: IAcquisitionModel) => acq.bookId === book.id);
				});
			})
		);

		const subs = [
			getBooks$.subscribe((data: IBookModel[]) => {
				setBooks(data);
			}),
			middleware.user.currentUser$.subscribe(setCurrentUser),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [middleware]);

	const handleActivateBook = () => {
		handleRouterLink('/menu/books/activate');
	};

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	return (
		<IonPage className="ion-text-center">
			<HeaderApp
				subTitle={`${currentUser?.firstName || currentUser?.lastName ? 'Bienvenid@' : ''}`}
				title={`${
					currentUser?.firstName || currentUser?.lastName
						? `${currentUser.firstName} ${currentUser.lastName}`
						: 'Bienvenid@'
				}`}
				background={'default'}
			/>
			<IonContent fullscreen>
				<IonText>
					<h4 className="ion-text-start ion-padding">
						<b>
							<span id="store-title">Mis cuadernos</span>
						</b>
					</h4>
				</IonText>
				<IonGrid>
					<BookGrid>
						{books.length ? (
							books.map((book: IBookModel) => {
								return (
									<BookCard
										key={book.id}
										book={book}
										middleware={middleware}
										onClick={() => handleRouterLink(`/menu/books/detail/${book.id}`)}
									/>
								);
							})
						) : (
							<IonGrid className="ion-text-center ion-margin-horizontal">
								{currentUser ? (
									<>
										<IonRow className="ion-text-center ion-margin-horizontal">
											<IonCol className="ion-padding-horizontal">
												<IonLabel className="ion-padding">
													<p>En estos momentos no tienes cuadernos activos en tu librería</p>
												</IonLabel>
											</IonCol>
										</IonRow>

										<div
											className="ion-justify-content-center pd-add-book-button"
											id="btn-add-book"
											onClick={handleActivateBook}
										>
											<p>Agregar cuaderno</p>
											<IonIcon icon={add} />
										</div>
									</>
								) : (
									<IonGrid>
										<span>Para poder tener cuadernos en tu librería, debes </span>
										<IonRouterLink routerLink={Config.UI_LOGIN_PAGE} routerDirection="none">
											<span id="lnk-login">ingresar con tu cuenta </span>
										</IonRouterLink>
										<span>primero.</span>
									</IonGrid>
								)}
							</IonGrid>
						)}

						{books.length > 0 && currentUser && (
							<IonGrid
								className="ion-justify-content-center pd-add-book-button ion-margin"
								id="btn-add-book"
								onClick={handleActivateBook}
							>
								<div>Agregar cuaderno </div>
								<div className="ion-padding">
									<IonIcon icon={add} />
								</div>
							</IonGrid>
						)}
					</BookGrid>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('AcquisitionsPage', 'component')(AcquisitionsPage);
