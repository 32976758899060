import {
	IonButton,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonRow,
	IonToggle,
} from '@ionic/react';
import { logOutOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IUserModel } from '../models/IUserModel';
import { Config } from '../utils/config';
import styles from './SettingsPage.module.scss';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('SettingsPage');

const SettingsPage: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();

	const [currentUser, setCurrentUser] = useState<IUserModel | null>();
	const [palmRejection, setPalmRejection] = useState<boolean>();
	const [deviceId, setDeviceId] = useState<string | null>();

	useEffect(() => {
		const subs = [
			middleware.user.currentUser$.subscribe(setCurrentUser),
			middleware.localConfig.palmRejection$.subscribe(setPalmRejection),
			middleware.device.deviceId$.subscribe(setDeviceId),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [middleware.localConfig.palmRejection$, middleware.user, middleware.device.deviceId$]);

	const handleLogout = async () => {
		try {
			await middleware.user.logout();
			notify.showSuccessNotify('¡Sesión cerrada correctamente!');
		} catch (error) {
			logger.exception(error, `SettingsPage.handleLogout: ${error}`);
			notify.showErrorNotify(error);
		}
	};

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	const handleSetPalmRejection = (value: boolean) => {
		middleware.localConfig.setPalmRejection(value);
	};

	return (
		<IonPage className={styles.SettingsPage}>
			<HeaderApp
				subTitle={`${currentUser?.firstName || currentUser?.lastName ? 'Bienvenid@' : ''}`}
				title={`${
					currentUser?.firstName || currentUser?.lastName
						? `${currentUser.firstName} ${currentUser.lastName}`
						: 'Bienvenid@'
				}`}
				background={'default'}
			/>
			<IonContent className="ion-padding-horizontal" fullscreen>
				{notify.notifyProps.show && <ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />}
				<IonGrid className="ion-text-center">
					<h2>Configuración</h2>
					{currentUser ? (
						<>
							<IonButton id="btn-edit-account" className="pd-button-primary" onClick={() => handleRouterLink(`/menu/account`)}>
								Editar cuenta
							</IonButton>
							<p />
							<IonGrid>
								<IonRow className="ion-justify-content-center">
									<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="4">
										<IonList lines="none">
											<IonItem
												id="btn-profiles"
												button
												detail={true}
												onClick={() => handleRouterLink(`/menu/profiles/list`)}
											>
												<IonLabel>Perfiles</IonLabel>
											</IonItem>
											<IonItem>
												<IonLabel>Dispositivo con lápiz activo</IonLabel>
												<IonToggle
													checked={palmRejection}
													onIonChange={(e) => handleSetPalmRejection(e.detail.checked)}
												/>
											</IonItem>
											<IonItem button detail={true} onClick={() => handleRouterLink(`/tutorial`)}>
												<IonLabel>Ver tutorial</IonLabel>
											</IonItem>
											{currentUser && (
												<IonItem mode="md" id="btn-logout" onClick={() => handleLogout()} button>
													<IonLabel>Cerrar sesión</IonLabel>
													<IonIcon slot="end" icon={logOutOutline} />
												</IonItem>
											)}
										</IonList>
									</IonCol>
								</IonRow>
							</IonGrid>
						</>
					) : (
						<>
							<h5 className="ion-padding">No has iniciado sesión</h5>
							<p>Para acceder a las funcionalidades de la app, debes ingresar con tu cuenta.</p>
							<IonRow>
								<IonCol size="12" className="ion-padding-top">
									<IonButton
										id="btn-login"
										className="pd-button-primary"
										onClick={() => handleRouterLink(Config.UI_LOGIN_PAGE)}
									>
										Ingresa a tu cuenta
									</IonButton>
								</IonCol>
							</IonRow>
						</>
					)}
				</IonGrid>
			</IonContent>
			<IonFooter className={styles.Footer}>
				Version: {Config.VERSION} [{Config.API_URL}] did: {deviceId}
			</IonFooter>
		</IonPage>
	);
};

export default withTransaction('SettingsPage', 'component')(SettingsPage);
