import React, { useState } from 'react';
import { IonPopover, IonButton, IonIcon, IonRange } from '@ionic/react';
import { ellipse, brush } from 'ionicons/icons';

const PencilSizeButton: React.FC<{
	size:
	| number
	| {
		lower: number;
		upper: number;
	}
	| undefined;
	showAsDisabled?: boolean;
	sizeHandler: (size: number) => void;
	onClickDisabledButton?: () => void | undefined;
}> = (props) => {
	const disabledButtonStyle = {
		opacity: 0.5,
		pointerEvents: "all"
	};

	const { size, sizeHandler, onClickDisabledButton = undefined, showAsDisabled = false } = props;

	const [showPopover, setShowPopover] = useState<{ open: boolean; event: Event | undefined }>({
		open: false,
		event: undefined,
	});

	const onSize = (event: CustomEvent) => {
		sizeHandler(event.detail.value);
	};

	return (
		<>
			<IonPopover
				isOpen={showPopover.open}
				event={showPopover.event}
				onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
			>
				<IonRange min={1} max={4} step={2} value={size} snaps={true} onIonChange={onSize}>
					<IonIcon size="small" slot="start" icon={brush} />
					<IonIcon slot="end" icon={brush} />
				</IonRange>
			</IonPopover>
			<IonButton
				id="btn-change-size"
				size="small"
				className="pd-control-page-button"
				style={showAsDisabled ? disabledButtonStyle : {}}
				onClick={(e) => {
					if (showAsDisabled) onClickDisabledButton?.();
					else setShowPopover({ open: true, event: e.nativeEvent });
				}}
			>
				<IonIcon icon={ellipse} />
			</IonButton>
		</>
	);
};

export default PencilSizeButton;
