import axios, { AxiosResponse } from 'axios';
import { Config } from '../utils/config';

export class APIRequest {
	static async post(urlFragment: string, data: any): Promise<AxiosResponse<any>> {
		let url = `${Config.API_URL}${urlFragment}`;
		const payload = JSON.stringify(data);
		const headers = { 'Content-Type': 'application/json' };

		const res = axios.request({
			url: url,
			method: 'POST',
			withCredentials: false,
			data: payload,
			headers: headers,
		});

		return res;
	}

	static async patch(urlFragment: string, id: number, data: any): Promise<AxiosResponse<any>> {
		const url = `${Config.API_URL}${urlFragment}?id=${id}`;

		const payload = JSON.stringify(data);
		const headers = { 'Content-Type': 'application/json' };

		const res = axios.request({
			url: url,
			timeout: 2000,
			method: 'PATCH',
			withCredentials: false,
			data: payload,
			headers: headers,
		});

		return res;
	}

	static async delete(urlFragment: string, data: any): Promise<AxiosResponse<any>> {
		const url = `${Config.API_URL}${urlFragment}?id=${data.id}`;
		const res = axios.request({
			url: url,
			method: 'DELETE',
			withCredentials: false,
		});

		return res;
	}

	static async get(urlFragment: string, pageSizes: number | undefined = undefined) {
		// TODO: revisar paginación en requests
		let url = `${Config.API_URL}${urlFragment}`;
		url = pageSizes ? `${url}?pageSize=${pageSizes}` : `${url}`;

		const res = axios.request({
			url: url,
			method: 'GET',
			withCredentials: false,
		});

		return res;
	}

	static async getById(urlFragment: string, id: number) {
		let url = `${Config.API_URL}${urlFragment}?id=${id}`;
		const res = axios.request({
			url: url,
			method: 'GET',
			withCredentials: false,
		});

		return res;
	}
}
