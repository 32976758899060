import { IonContent, IonSlide, IonSlides } from '@ionic/react';
import React from 'react';
import img1 from '../assets/tutorial-1.jpg';
import img2 from '../assets/tutorial-2.jpg';
import img3 from '../assets/tutorial-3.jpg';
import { TutorialSlide } from '../components/TutorialSlide';
import { IonFullscreenPage } from '../helpers/Fullscreen';

const TutorialPage: React.FC = () => {
	return (
		<IonFullscreenPage>
			<IonContent fullscreen>
				<IonSlides pager={true}>
					<IonSlide>
						<TutorialSlide
							image={img1}
							title="Bienvenidos a Caligrafix!"
							content="Antes de usar un cuaderno digital primero debes descargarlo. Ingresando el código impreso en tu cuaderno físico accederás a todas sus actividades."
						/>
					</IonSlide>
					<IonSlide>
						<TutorialSlide
							image={img2}
							title="Rayado digital"
							content="Raya tu cuaderno digital todas las veces que quieras. Podrás guardar, borrar o rehacer tus actividades en todo momento."
						/>
					</IonSlide>
					<IonSlide>
						<TutorialSlide
							image={img3}
							title="Administra tus perfiles"
							content="Agrega perfiles a tu cuaderno. Cada perfil tendrá su cuaderno digital y podrás revisar cada uno de sus avances."
							isLastSlide={true}
						/>
					</IonSlide>
				</IonSlides>
			</IonContent>
		</IonFullscreenPage>
	);
};

export default TutorialPage;
