import { IonButton, IonGrid, IonIcon, IonPopover } from '@ionic/react';
import { colorPaletteOutline } from 'ionicons/icons';
import React, { useState } from 'react';

export class ColorModel {
	constructor(public name: string, public color: string) { }
}

export const PencilColorButton: React.FC<{
	color: ColorModel;
	colors: ColorModel[];
	colorHandler: (color: ColorModel) => void;
	showAsDisabled?: boolean;
	onClickDisabledButton?: () => void | undefined;
}> = (props) => {
	const disabledButtonStyle = {
		opacity: 0.5,
		pointerEvents: "all"
	};

	const { color, colors, colorHandler, onClickDisabledButton = undefined, showAsDisabled = false } = props;

	const [showPopover, setShowPopover] = useState<{ open: boolean; event: Event | undefined }>({
		open: false,
		event: undefined,
	});

	const onChangeColor = (color: ColorModel) => {
		colorHandler(color);
		setShowPopover({ open: false, event: undefined });
	};

	return (
		<>
			<IonPopover
				isOpen={showPopover.open}
				event={showPopover.event}
				onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
			>
				<IonGrid className="ion-text-center" style={{ width: '90%' }}>
					{colors.map((color) => {
						return (
							<IonButton
								id={`btn-color-${color.name}`}
								key={color.name}
								size="small"
								className="pd-color-button"
								color={color.name}
								onClick={onChangeColor.bind(globalThis, color)}
							/>
						);
					})}
				</IonGrid>
			</IonPopover>
			<IonButton
				className="pd-control-page-button"
				id="btn-change-color"
				size="small"
				color={color.name}
				style={showAsDisabled ? disabledButtonStyle : {}}
				onClick={(e) => {
					if (showAsDisabled) onClickDisabledButton?.();
					else setShowPopover({ open: true, event: e.nativeEvent });
				}}
			>
				<IonIcon icon={colorPaletteOutline} />
			</IonButton>
		</>
	);
};
