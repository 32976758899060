import { IonButton, IonContent, IonGrid, IonIcon, IonPage, IonRow } from '@ionic/react';
import { cloudOffline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { BookCover } from '../components/BookCover';
import { HeaderApp } from '../components/HeaderApp';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IBookModel } from '../models/IBookModel';
import { IGrantModel } from '../models/IGrantModel';
import styles from './BookWithoutGrant.module.scss';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const BookWithoutGrant: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const authorizationId: number = +useParams<{ authorizationId: string }>().authorizationId;
	const bookId: number = +useParams<{ bookId: string }>().bookId;
	const [internet, setInternet] = useState(true);
	const [book, setBook] = useState<IBookModel | null>(null);
	const [grant, setGrant] = useState<IGrantModel>();

	const history = useHistory();

	// Grant logic
	useEffect(() => {
		const isValidForAuth$ = middleware.grants.isValidForAuth$(authorizationId);
		const grant$ = middleware.grants.forAuth$(authorizationId);
		const book$ = middleware.books.byId$(bookId);
		const networkStatus$ = middleware.networkStatus.apiStatus$;

		const subs = [
			isValidForAuth$.subscribe((grant) => {
				if (grant) {
					history.replace(`/books/read/${authorizationId}/${bookId}`, { direction: 'none' });
				}
			}),
			grant$.subscribe(setGrant),
			book$.subscribe(setBook),
			networkStatus$.subscribe(setInternet),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [bookId, authorizationId, history, middleware.grants, middleware.books, middleware.networkStatus]);

	return (
		<IonPage>
			<HeaderApp goBackTo={`/menu/books/detail/${bookId}`} />
			<IonContent className="ion-padding-horizontal" fullscreen>
				<IonGrid className="ion-text-center">
					<h3 className={styles.BookTitle}>Cuaderno en uso en otro dispositivo</h3>
					<IonRow className="ion-justify-content-center ion-padding">
						{book && (
							<div className={styles.BookCoverShadow}>
								<BookCover book={book} middleware={middleware} />
							</div>
						)}
					</IonRow>
					{internet ? (
						<>
							<p className={styles.BookSubtitle}>
								Actualmente este cuaderno se está usando en {}
								{grant?.deviceName ? `el dispositivo "${grant.deviceName}"` : 'otro dispositivo'}.
							</p>
							<br />
							<IonButton
								id="btn-authorize-device"
								className="pd-button-primary"
								onClick={async () => {
									try {
										await middleware.grants.requestGrant(authorizationId, true);
										history.replace(`/books/read/${authorizationId}/${bookId}`, { direction: 'none' });
									} catch (error) {
										history.replace(`/menu/library`, { direction: 'none' });
									}
								}}
							>
								Usar en este dispositivo
							</IonButton>
							<p>Al usar el cuaderno en este dispositivo, se cerrará en el otro dispositivo.</p>
						</>
					) : (
						<>
							<IonIcon size="large" icon={cloudOffline} />
							<p>Para poder usar el cuaderno en este dispositivo, tienes que conectarte a internet.</p>
						</>
					)}
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('BookWithoutGrant', 'component')(BookWithoutGrant);
