const FIELD_NAMES_ES: { [name: string]: string } = {
	email: 'Correo electrónico',
	password: 'Contraseña',
	firstName: 'Nombre',
	lastName: 'Apellido',
	pin: 'PIN',
	activationCode: 'Código de activación',
	code: 'Código',
	name: 'Nombre',
	recoverPasswordCode: 'Código de verificación',
};

const ERROR_MESSAGES_ES: { [name: string]: string } = {
	'Network Error': 'Error de comunicación con el servidor',
	'Default Error': 'Error de comunicación con el servidor, por favor reintentar más tarde',
};

const ERROR_MESSAGES = ERROR_MESSAGES_ES;
const FIELD_NAMES = FIELD_NAMES_ES;

export class ErrorWrapper {
	public readonly title: string;
	public readonly detail: string[];

	// Store the original error for debugging purposes
	private readonly original: any;

	constructor(error: any) {
		this.original = error;
		// errores enviados por api
		if (error.response) {
			switch (error.response.data.type) {
				case 'body':
				case 'query':
					let errors: any = [];
					error.response.data.errors.forEach((err: any) => {
						Object.values(err.constraints).forEach((val) => {
							const translatedName = FIELD_NAMES[err.property] || err.property;
							errors.push(`<b>${translatedName}</b>: ${val}`);
						});
					});

					this.detail = errors;
					this.title =
						error.response.data.errors.length > 1 || this.detail.length > 1
							? '¡Han ocurrido los siguientes errores!'
							: '¡Ha ocurrido el siguiente error!';

					break;
				case 'auth':
				case 'logic':
					this.title = '¡Ha ocurrido un error!';
					this.detail = [error.response.data.error];
					break;
				case 'rateLimit':
					this.title = '¡Ha ocurrido un error!';
					this.detail = [error.response.data.error];
					break;
				default:
					this.title = '¡Ha ocurrido un error!';
					const translatedMessage = ERROR_MESSAGES['Default Error'];
					this.detail = [translatedMessage];
					break;
			}
		} else {
			// errores que no son enviados por api (ej: localdb)
			this.title = '¡Ha ocurrido el siguiente error!';
			const translatedMessage = ERROR_MESSAGES[error.message] || error.message;
			this.detail = [translatedMessage];
		}
	}
}
