import {
	IonButton,
	IonCard,
	IonCol,
	IonGrid,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonRouterLink,
	IonRow,
	IonText
} from '@ionic/react';
import dayjs from 'dayjs';
import { bookOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IDeepAcquisitionModel } from '../models/IAcquisitionModel';
import { IDeepAuthorizationModel } from '../models/IAuthorizationModel';
import { IBookModel } from '../models/IBookModel';
import { Config } from '../utils/config';

export const BookAcquisitions: React.FC<{
	book: IBookModel;
	middleware: AppMiddleware;
	onOpen: (auth: IDeepAuthorizationModel | null) => void;
}> = ({ book, middleware, onOpen }) => {
	const [acquisitions, setAcquisitions] = useState<IDeepAcquisitionModel[]>([]);

	useEffect(() => {
		const subs = [middleware.acquisitions.getDeepAcqs$(book.id).subscribe(setAcquisitions)];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [book, middleware]);

	return (
		<>
			{acquisitions?.length > 0 && (
				<IonGrid>
					<IonRow className="ion-justify-content-center">
						<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="8" sizeXl="8">
							{acquisitions.map((acq) => {
								let expirationDate = new Date(acq.expirationDate).toJSON().slice(0, 10).split('-').reverse().join('-');
								let acqExpired = dayjs() >= dayjs(acq.expirationDate);
								return (
									<IonCard key={acq.id}>
										<h6 className='ion-padding ion-margin pd-acquisition-card-title'>
											{Config.UI_DEBUG_ENTITY_IDS && <span>[#{acq.id}] </span>}
											Código de activación con acceso hasta el {expirationDate}
										</h6>
										<IonList lines="none" mode="md">
											{acq.authorizations.length > 0 ? (
												<>
													{acqExpired &&
														<IonText color='danger'>
															<h6 className="ion-text-center ion-no-margin ion-margin-bottom ion-margin-horizontal">
																<span id={`warning-with-profiles-${acq.id}`}>Código de activación se encuentra vencido, <b style={{ textDecoration: "underline" }}>ya no podrás rayar.</b></span>
															</h6>
														</IonText>
													}
													{acq.authorizations.map((auth) => {
														return (
															<div className="pd-book-acquisitions-card" key={auth.id}>
																<IonItem id={`btn-draw-mode-${auth.id}`} onClick={() => onOpen(auth)} button>
																	<IonLabel>
																		{Config.UI_DEBUG_ENTITY_IDS && <span> [#{auth.id}] </span>}
																		<b>{auth.profile?.name}</b>
																	</IonLabel>
																	<IonIcon slot="end" icon={bookOutline} />
																</IonItem>
															</div>
														);
													})}
												</>
											) : (
												<IonText color={acqExpired ? 'danger' : ''} className="ion-text-center">
													<h6 className='ion-no-padding ion-no-margin'>
														<span id={`warning-without-profiles-${acq.id}`}>{acqExpired ? "El acceso a este cuaderno ya no está disponible." : "No tienes perfiles autorizados."}</span>
													</h6>
												</IonText>
											)}
										</IonList>

										{/* Link to profiles is only if acquisiton has not expired */}
										<IonRow className="ion-text-center">
											<IonCol>
												{!acqExpired &&
													<IonRouterLink routerLink={`/menu/books/profiles/${acq.id}`} routerDirection="none">
														<span id={`btn-authorize-profile-${acq.id}`}>
															<h6 className="ion-no-margin ion-padding">
																<b>Autorizar {acq.authorizations.length > 0 && "otro "}perfil</b>
															</h6>
														</span>
													</IonRouterLink>
												}
											</IonCol>
										</IonRow>
									</IonCard>
								);
							})}
						</IonCol>
					</IonRow>
				</IonGrid>
			)}
			<IonGrid>
				<IonRow className="ion-text-center">
					<IonCol size="12">
						<IonRouterLink
							style={{ textDecoration: 'underline' }}
							routerLink={`/menu/books/activate/${book.id}`}
							routerDirection="none"
						>
							<IonButton className="pd-button-outline" id="btn-add-activation-code">
								{acquisitions?.length > 0 ? <>Ingresar otro código</> : <>Ingresar código de activación</>}
							</IonButton>
						</IonRouterLink>
					</IonCol>
				</IonRow>

				{acquisitions?.length > 0 && (
					<IonRow className="ion-text-center">
						<IonCol>
							<IonButton id="btn-read-mode" className="pd-button-primary" onClick={() => onOpen(null)}>
								Abrir en modo lectura
							</IonButton>
						</IonCol>
					</IonRow>
				)}
			</IonGrid>
		</>
	);
};
