// Reactjs
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonList, IonPage, IonRouterLink, IonRow } from '@ionic/react';
import { logoGoogle } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormInput } from '../components/FormInput';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { Config } from '../utils/config';
import { Logger } from '../utils/logging';
import { cleanupEmail } from '../utils/cleanupEmail';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('LoginPage');

interface IUserLogin {
	email: string;
	password: string;
}

const LoginPage: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();
	const { handleSubmit, control, errors, setValue, getValues } = useForm();

	useEffect(() => {
		const userEmail = middleware.user.getLastLoginEmail();
		userEmail && setValue('email', userEmail);
	}, [middleware.user, setValue]);

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	const handleUserLoginOauth = async (application: string) => {
		window.open(`${Config.API_URL}/auth/${application}`, '_self');
	};

	const handleLogin = async (email: string, password: string) => {
		try {
			await middleware.user.loginByEmail(email, password);
			const redirectAfterLogin = localStorage.getItem('pathname');
			handleRouterLink(redirectAfterLogin!);
		} catch (error) {
			logger.exception(error, 'handleLogin');
			notify.showErrorNotify(error, 0);
		}
	};

	const handleUserLogin = async (data: IUserLogin) => {
		handleLogin(data.email, data.password);
	};

	const handleDebugDefaultUserLogin = async () => {
		handleLogin(Config.UI_DEBUG_DEFAULT_USER, Config.UI_DEBUG_DEFAULT_PASSWORD);
	};
	return (
		<IonPage>
			{<HeaderApp goBackTo={Config.UI_START_PAGE} />}
			{notify.notifyProps.show && <ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />}
			<IonContent className="ion-text-center ion-padding" fullscreen>
				<IonGrid className="ion-text-center">
					<h2>
						<b>Iniciar Sesión</b>
					</h2>
					<h3>¿Ya tienes una cuenta?</h3>
					<div>Ingresa tu mail y tu contraseña para seguir</div>

					<IonList className="ion-padding">
						<form onSubmit={handleSubmit(handleUserLogin)} onBlur={() => cleanupEmail(getValues, setValue)}>
							<FormInput
								label="Correo electrónico"
								name="email"
								control={control}
								errors={errors}
								rules={{
									required: '¡Debe ingresar un email válido!',
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
										message: '¡Email inválido!',
									},
								}}
							/>
							<FormInput
								label="Contraseña"
								name="password"
								control={control}
								errors={errors}
								type="password"
								rules={{
									required: '¡Debe ingresar una contraseña!',
									minLength: {
										value: 8,
										message: '¡Contraseña debe tener al menos 8 caracteres!',
									},
								}}
							/>

							<IonRow>
								<IonCol size="12" className="ion-padding-top">
									<IonButton type="submit" className="pd-button-primary-large">
										Ingresar
									</IonButton>
								</IonCol>

								{Config.UI_DEBUG_DEFAULT_USER && (
									<IonCol>
										<IonButton onClick={handleDebugDefaultUserLogin} className="pd-button-default">
											[default]
										</IonButton>
									</IonCol>
								)}

								<IonCol size="12" className="ion-padding">
									<IonRouterLink routerLink="/recovery-password" routerDirection="none">
										<span id="btn-forgot-password">¿Olvidaste tu contraseña?</span>
									</IonRouterLink>
								</IonCol>
							</IonRow>
						</form>
					</IonList>

					<h3>¿Aún no tienes cuenta?</h3>

					<IonList className="ion-padding">
						<IonRow>
							<IonCol>
								<IonButton
									className="pd-button-outline-large"
									id="btn-create-account"
									onClick={() => handleRouterLink(`/signup`)}
								>
									Crea una cuenta
								</IonButton>
							</IonCol>
						</IonRow>
						{Config.UI_USE_GOOGLE_AUTHENTICATION && (
							<IonRow>
								<IonCol>
									<IonButton className="ion-pading" onClick={() => handleUserLoginOauth('google')}>
										<IonIcon size="small" slot="start" icon={logoGoogle} />
										CONTINUAR CON GOOGLE
									</IonButton>
								</IonCol>
							</IonRow>
						)}
					</IonList>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('LoginPage', 'component')(LoginPage);