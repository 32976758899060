import { Config } from './config';
import { apm } from './rum';

export const LOG_LEVELS = {
	error: 0,
	warn: 1,
	info: 2,
	http: 3,
	verbose: 4,
	debug: 5,
	silly: 6,
};

const LEVEL_NAMES = ['error', 'warn', 'info', 'http', 'verbose', 'debug', 'silly'];

const LEVEL_NAMES_MAP = new Map(LEVEL_NAMES.map((name, index) => [name, index]));

/**
 * Logging facility that should be used instead of calls to
 * console.log/console.error.
 *
 * Usage:
 * At the beginning of each file, after imports, create a logger
 * with the same name as the file:
 * const logger = new Logger('SomeFile');
 *
 * Call any of the logging methods on that logger:
 * logger.info('Some data', data);
 *
 * Ideally, replace messages that can be computationally costly to
 * compute by functions that return them, as they are called only
 * if the log is emitted:
 * logger.info(() => `This call is costly: ${costlyCall()}`, data);
 *
 * Configuration:
 * The APP_ENV_LOGGER_CONFIG environment variable can contain a
 * string that represents a JSON object to configure the loggers.
 * Keys are logger names (or * to define a default level), and
 * values are log level names ("info", "debug", etc.)
 */
export class Logger {
	constructor(private name: string, private defaultLevel: number | undefined = undefined) {}

	public exception(err: any, ...args: any[]) {
		this.event(LOG_LEVELS.error, ...args);
		apm.captureError(err);
	}
	public error(...args: any[]) {
		this.event(LOG_LEVELS.error, ...args);
	}
	public warn(...args: any[]) {
		this.event(LOG_LEVELS.warn, ...args);
	}
	public info(...args: any[]) {
		this.event(LOG_LEVELS.info, ...args);
	}
	public http(...args: any[]) {
		this.event(LOG_LEVELS.http, ...args);
	}
	public verbose(...args: any[]) {
		this.event(LOG_LEVELS.verbose, ...args);
	}
	public debug(...args: any[]) {
		this.event(LOG_LEVELS.debug, ...args);
	}
	public silly(...args: any[]) {
		this.event(LOG_LEVELS.silly, ...args);
	}

	private event(level: number, ...args: any[]) {
		if (!CONFIG.shouldLog(this.name, level, this.defaultLevel)) return;

		args = args.map((arg) => (typeof arg === 'function' ? arg() : arg));

		const prefix = `${Date.now()} ${LEVEL_NAMES[level]} [${this.name}]`;

		if (level <= LOG_LEVELS.warn) console.error(prefix, ...args);
		else console.log(prefix, ...args);
	}
}

class LoggerConfig {
	private levels = new Map<string, number>();
	private defaultLevel: number = LOG_LEVELS.info;

	constructor() {
		try {
			const config = JSON.parse(Config.LOGGER_CONFIG);
			for (const [name, levelName] of Object.entries(config)) {
				const level = LEVEL_NAMES_MAP.get(levelName as string);
				if (level === undefined) throw new Error(`Cannot parse level '${JSON.stringify(levelName)}' for '${name}'`);

				if (name === '*') this.setDefaultLevel(level);
				else this.setLevel(name, level);
			}
		} catch (e) {
			console.error('Could not parse LOGGER_CONFIG', Config.LOGGER_CONFIG, e);
		}
	}

	shouldLog(name: string, level: number, defaultLevel: number | undefined): boolean {
		const targetLevel = this.levels.get(name) ?? defaultLevel ?? this.defaultLevel;
		return level <= targetLevel;
	}

	setDefaultLevel(defaultLevel: number) {
		this.defaultLevel = defaultLevel;
	}

	setLevel(name: string, level: number) {
		this.levels.set(name, level);
	}
}

const CONFIG = new LoggerConfig();

// Make the logger config globally available so that we can
// change it from the browser console
(window as any).__loggerconfig = CONFIG;
