import {
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonIcon,
	IonList,
	IonLoading,
	IonPage,
	IonRow,
	IonTitle,
} from '@ionic/react';
import { checkmarkCircleOutline, keyOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormInput } from '../components/FormInput';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { Config } from '../utils/config';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('ChangePasswordPage');

interface IChangeInfo {
	password: string;
	email: string;
	recoverPasswordCode: string;
}

const ChangePasswordPage: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();

	const params: any = history.location.state;
	const email: string = params?.email;

	const [sendRecoveryInfo, setSendRecoveryInfo] = useState<boolean>(false);
	const [showLoading, setShowLoading] = useState<boolean>(false);
	const { handleSubmit, control, errors, getValues } = useForm();

	const handleChangePassword = async (data: IChangeInfo) => {
		setShowLoading(true);
		try {
			await middleware.user.changePassword(email ? email : data.email, data.password, data.recoverPasswordCode);
			setSendRecoveryInfo(true);
			localStorage.setItem('pathname', Config.UI_START_PAGE);
		} catch (error) {
			logger.exception(error, `handleChangePassword: ${error}`);
			notify.showErrorNotify(error);
		}
		setShowLoading(false);
	};

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	return (
		<IonPage>
			<HeaderApp goBackTo={!sendRecoveryInfo ? '/recovery-password' : ''} />
			<IonContent className="ion-text-center ion-padding-horizontal" fullscreen>
				{notify.notifyProps.show && <ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />}
				<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Cargando...'} />
				{sendRecoveryInfo ? (
					<IonGrid>
						<IonRow className="ion-justify-content-center">
							<IonCol size="12">
								<IonIcon color="success" className="pd-page-title-icon" icon={checkmarkCircleOutline} />
							</IonCol>
							<IonCol className="ion-padding" sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="10" sizeXl="12">
								<b>
									<span id="result-message">Contraseña cambiada exitosamente</span>
								</b>

								<IonTitle size="small">Puedes volver a iniciar sesión.</IonTitle>
							</IonCol>
						</IonRow>
						<IonRow className="ion-padding">
							<IonCol>
								<IonButton id="btn-login" className="pd-button-primary" onClick={() => handleRouterLink(`/login`)}>
									Iniciar Sesión
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				) : (
					<IonGrid>
						<IonRow className="ion-justify-content-center">
							<IonCol size="12">
								<IonIcon className="pd-page-title-icon" icon={keyOutline} />
							</IonCol>
							<IonCol sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="10" sizeXl="12">
								<h5>
									<b>Crear nueva contraseña </b>
								</h5>
							</IonCol>
							<IonCol sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="10" sizeXl="12">
								<IonTitle size="small">
									Debes indicar el código de verificación enviado a{' '}
									{email ? (
										<p>
											<b>{`${email}`}</b>
										</p>
									) : (
										'tu correo electrónico.'
									)}
								</IonTitle>
							</IonCol>
						</IonRow>

						<IonList className="ion-padding">
							<form onSubmit={handleSubmit(handleChangePassword)}>
								{!email && (
									<FormInput
										label="Correo electrónico"
										name="email"
										control={control}
										errors={errors}
										rules={{
											required: '¡Debe ingresar un email válido!',
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: '¡Email inválido!',
											},
										}}
									/>
								)}

								<FormInput
									label="Código de verificación"
									name="recoverPasswordCode"
									control={control}
									errors={errors}
									type="number"
									rules={{
										pattern: {
											value: /^[0-9]+$/,
											message: '¡Debe ingresar solo números!',
										},
										required: '¡Debe ingresar un código!',
										minLength: {
											value: 6,
											message: 'Código debe tener 6 caracteres!',
										},
										maxLength: {
											value: 6,
											message: 'Código debe tener 6 caracteres!',
										},
									}}
								/>

								<FormInput
									label="Nueva contraseña"
									name="password"
									control={control}
									errors={errors}
									type="password"
									rules={{
										required: '¡Debe ingresar una contraseña!',
										minLength: {
											value: 8,
											message: '¡Contraseña debe tener al menos 8 caracteres!',
										},
									}}
								/>

								<FormInput
									label="Confirmar contraseña"
									name="confirmPassword"
									control={control}
									errors={errors}
									type="password"
									rules={{
										required: '¡Debe confirmar la contraseña!',
										minLength: {
											value: 8,
											message: '¡Contraseña debe tener al menos 8 caracteres!',
										},
										validate: {
											matchesPreviousPassword: (value) => {
												return getValues('password') === value || '¡Contraseñas deben ser iguales!';
											},
										},
									}}
								/>

								<IonRow className="ion-padding ion-justify-content-center">
									<IonCol sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="10" sizeXl="12">
										<IonButton type="submit" className="pd-button-primary">
											Cambiar contraseña
										</IonButton>

										<IonButton
											id="btn-recover-code"
											className="pd-button-outline"
											onClick={() => handleRouterLink('/recovery-password')}
										>
											Volver a enviar código
										</IonButton>
									</IonCol>
								</IonRow>
							</form>
						</IonList>
					</IonGrid>
				)}
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('ChangePasswordPage', 'component')(ChangePasswordPage);
