import { IonButton, IonCol, IonContent, IonList, IonPage, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { useHistory, useParams } from 'react-router';
import { FormInput } from '../components/FormInput';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IProfileModel } from '../models/IProfileModel';
import { IAcquisitionModel } from '../models/IAcquisitionModel';
import { IAuthorizationModel } from '../models/IAuthorizationModel';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('ProfileActionPage');

const ProfileActionPage: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();

	const profileAction = useParams<{ profileAction: string }>().profileAction;
	const profileId = +useParams<{ profileId: string }>().profileId;
	const acquisitionId = +useParams<{ acquisitionId: string }>().acquisitionId;

	const [profile, setProfile] = useState<IProfileModel | null>();
	const [profiles, setProfiles] = useState<IProfileModel[] | null>();
	const [acquisition, setAcquisition] = useState<IAcquisitionModel>();
	const [authorizations, setAuthorizations] = useState<IAuthorizationModel[]>([]);
	const { handleSubmit, control, errors, reset } = useForm();

	useEffect(() => {
		const getDetailAcqs$ = combineLatest([
			middleware.authorizations.all$,
			middleware.profiles.all$,
			middleware.acquisitions.all$,
		]).pipe(
			map(([auths, profiles, acqs]) => {
				if (acquisitionId) {
					return acqs
						.map((acq: IAcquisitionModel) => {
							const filteredAuths = auths.filter((auth) => auth.bookAcquisitionId === acquisitionId);
							const filteredProf = profiles.filter((prof) => profileId === prof.id)[0];
							return {
								acquisition: acq,
								authorizations: filteredAuths,
								profilesSelected: filteredProf,
								profiles: profiles,
							};
						})
						.filter((acq) => (acquisitionId ? acq.acquisition.id === acquisitionId : acq));
				} else {
					return [
						{
							acquisition: [],
							authorizations: [],
							profilesSelected: profiles.filter((prof: IProfileModel) => prof.id === profileId)[0],
							profiles: profiles,
						},
					];
				}
			})
		);

		let subscription = getDetailAcqs$.subscribe((data: any[]) => {
			if (data[0]) {
				const profilesSelected = data[0].profilesSelected || null;
				setAuthorizations(data[0].authorizations);
				setAcquisition(data[0].acquisition);
				setProfiles(data[0].profiles);
				setProfile(profilesSelected);
				reset({
					name: profilesSelected?.name || '',
					birthDate: profilesSelected?.birthDate || null,
				});
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [profileId, profileAction, acquisitionId, middleware, reset]);

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	const handleAddItem = (data: any) => {
		middleware.profiles
			.addData({ ...profile, ...data })
			.then(async () => {
				if (acquisitionId && acquisition && authorizations.length < acquisition.authorizationQuotas) {
					const localProfiles = await middleware.profiles.getAllLocalData();
					const newProfile = localProfiles.filter((prof) => !profiles?.find(({ id }) => prof.id === id))[0];

					let data = { profileId: newProfile.id, bookAcquisitionId: acquisitionId };
					await middleware.authorizations.addData(data);
				}

				handleRouterLink(`/menu/profiles/list/${acquisitionId ? acquisitionId : ''}`);
			})
			.catch((error) => {
				logger.exception(error, `handleAddItem: ${error}`);
				notify.showErrorNotify(error);
			});
	};

	const handleEditItem = (data: any) => {
		middleware.profiles
			.editData(profile!.id, data)
			.then(() => handleRouterLink(`/menu/profiles/list/${acquisitionId ? acquisitionId : ''}`))
			.catch((error) => {
				logger.exception(error, `handleEditItem: ${error}`);
				notify.showErrorNotify(error);
			});
	};

	const handleSave = (data: any) => {
		switch (profileAction) {
			case 'add':
				handleAddItem(data);
				break;
			case 'edit':
				handleEditItem(data);
				break;
			default:
				throw new Error(`Action not handled: ${profileAction}`);
		}
	};

	return (
		<IonPage className="ion-text-center">
			<HeaderApp goBackTo={`/menu/profiles/list/${acquisitionId ? acquisitionId : ''}`} />
			<IonContent className="ion-padding ion-text-center" fullscreen>
				{notify.notifyProps.show && <ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />}
				<IonList className="ion-padding">
					<form onSubmit={handleSubmit(handleSave)}>
						<FormInput
							label="Nombre del niño/a"
							name="name"
							control={control}
							errors={errors}
							rules={{
								required: '¡Debe ingresar un nombre!',
							}}
						/>

						<FormInput label="Fecha de nacimiento" name="birthDate" control={control} errors={errors} type="date" />

						<IonRow className="ion-padding">
							<IonCol>
								<IonButton className="pd-button-primary" type="submit">
									Guardar
								</IonButton>
							</IonCol>
						</IonRow>
					</form>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('ProfileActionPage', 'component')(ProfileActionPage);
