import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { IUserModel } from '../models/IUserModel';
import { Config } from '../utils/config';
import { ErrorWrapper } from '../utils/ErrorWrapper';
import { APIRequest } from './APIRequest';
import { PublicResourceMiddleware } from './PublicResourceMiddleware';
import { Logger } from '../utils/logging';
import { apm } from '../utils/rum';

const logger = new Logger('UserMiddleware');

// Inject token into http headers
axios.interceptors.request.use(
	async (config) => {
		const token = localStorage.getItem('token');
		if (token) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${token}`,
			};
		}

		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

export class UserMiddleware {
	private readonly userResourceDelegate = new PublicResourceMiddleware<IUserModel>('user', '/my-account');

	public readonly currentUser$ = new BehaviorSubject<IUserModel | null>(null);

	constructor() {
		// We use a BehaviorSubject + subscription instead of pipe + map
		// because we need to have currentUser$.value
		this.userResourceDelegate.all$.subscribe((users) => {
			const user = localStorage.getItem('token') && users.length === 1 && users[0].loggedIn ? users[0] : null;
			logger.info('current user', user);
			this.currentUser$.next(user);
			apm.setUserContext({
				id: user?.id,
				email: user?.email,
			});
		});
	}

	public async ready() {
		await this.userResourceDelegate.ready();
		logger.verbose('ready');
	}

	/**
	 * Internal method to perform pre-login cleanup and post-login
	 * setup. As we have two different login mechanisms (by mail / by token),
	 * this method performs the common steps.
	 */
	private async loginInternal(login: () => Promise<IUserModel>) {
		logger.info('login');
		try {
			// Remove previous users
			const previousUsers = await this.userResourceDelegate.getAllLocalData();

			previousUsers.map(async (user: IUserModel) => {
				await this.userResourceDelegate.removeLocalData(user.id);
			});

			const user = await login();

			localStorage.setItem('userEmail', user.email);
			await this.userResourceDelegate.editLocalData(user.id, { ...user, loggedIn: true });

			logger.info('Logged in');
		} catch (error) {
			logger.exception(error, 'UserMiddleware.login - error');
			this.logout();
			throw new ErrorWrapper(error);
		}
	}

	public async loginByEmail(email: string, password: string) {
		await this.loginInternal(async () => {
			// Call login API
			const response = await APIRequest.post('/auth/login', { email, password });
			logger.info('login - API response', response);

			const token = response.data.token;
			if (token) localStorage.setItem('token', token);

			return response.data;
		});
	}

	public async loginWithToken(token: string) {
		await this.loginInternal(async () => {
			// Force token and retrieve user data
			localStorage.setItem('token', token);

			const response = await APIRequest.get('/my-account');

			return response.data;
		});
	}

	/**
	 * Verifies that the user is still authenticated with the backend
	 */
	public async checkAuth() {
		logger.verbose('checkAuth');

		if (!this.currentUser$.value) {
			logger.info('checkAuth - not logged in, skipping');
			return;
		}

		try {
			await axios.request({
				url: `${Config.API_URL}/auth/check`,
				withCredentials: false,
			});
			logger.info('checkAuth - ok');
		} catch (error) {
			// Control para errores con status_code
			logger.info('checkAuth - error.response.status', error.response && error.response.status);
			if (error.response && error.response.status === 401) {
				logger.error('checkAuth - 401, logging out');
				await this.logout();
			} else {
				logger.error('checkAuth failed', error);
				return;
			}
		}
	}

	public async logout() {
		try {
			logger.info('logout');
			if (this.currentUser$.value) {
				const updateUserData = {
					...this.currentUser$.value,
					loggedIn: false,
				};
				await this.userResourceDelegate.editLocalData(updateUserData.id, updateUserData);

				await axios.request({
					url: `${Config.API_URL}/auth/logout`,
					method: 'POST',
					withCredentials: false,
				});
			}
			localStorage.removeItem('token');
		} catch (error) {
			logger.exception(error, 'logout');
			throw new ErrorWrapper(error);
		}
	}

	async signUp(email: string, password: string) {
		try {
			let res = await APIRequest.post('/auth/register', { email, password });
			// Se registra nuevo usuario
			await this.userResourceDelegate.addLocalData(res.data.id, { email, password });
		} catch (error) {
			logger.exception(error, 'signUp');
			throw new ErrorWrapper(error);
		}
	}

	async activateAccount(email: string, activationCode: string) {
		try {
			const response = await APIRequest.post('/auth/activate_account', { email, activationCode });
			const token = response.data.token;
			if (token) localStorage.setItem('token', token);

			await this.loginWithToken(token);
		} catch (error) {
			logger.exception(error, `activateUserAccount: ${error}`);
			throw new ErrorWrapper(error);
		}
	}

	async recoverPassword(email: string) {
		try {
			await APIRequest.post('/auth/recover_password', { email });
		} catch (error) {
			logger.exception(error, `recoverUserPassword: ${error}`);
			throw new ErrorWrapper(error);
		}
	}

	async changePassword(email: string, password: string, recoverPasswordCode: string) {
		try {
			await APIRequest.post('/auth/change_password', {
				password,
				email,
				recoverPasswordCode,
			});
		} catch (error) {
			logger.exception(error, `changePassword: ${error}`);
			throw new ErrorWrapper(error);
		}
	}

	async changeData(id: number, data: IUserModel) {
		return await this.userResourceDelegate.editData(id, data);
	}

	async recoverActivationCode(email: string) {
		try {
			await APIRequest.post(`/auth/recover_activation_code`, { email });
		} catch (error) {
			logger.exception(error, `recoverActivationCode: ${error}`);
			//throw new Error(`¡Ha ocurrido un error al recuperar código de activación de cuenta!`);
			throw new ErrorWrapper(error);
		}
	}

	getLastLoginEmail() {
		try {
			const userEmail = localStorage.getItem('userEmail');
			return userEmail;
		} catch (error) {
			logger.exception(error, `getLastLoginEmail: ${error}`);
			throw new ErrorWrapper(error);
		}
	}
}
