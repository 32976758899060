import {
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonIcon,
	IonList,
	IonLoading,
	IonPage,
	IonRow,
	IonTitle,
} from '@ionic/react';
import { checkmarkCircleOutline, personCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormInput } from '../components/FormInput';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('ActivateAccountPage');

interface IActivateInfo {
	email: string;
	activationCode?: string;
}

const ActivateAccountPage: React.FC<{ middleware: AppMiddleware }> = (props) => {
	const history = useHistory();
	const notify = useNotify();
	const params: any = history.location.state;
	const middleware = props.middleware;
	const email: string = params?.email;
	const [activatedAccount, setActivatedAccount] = useState<boolean>(false);
	const [showLoading, setShowLoading] = useState<boolean>(false);
	const { handleSubmit, control, errors, getValues, trigger, clearErrors } = useForm();

	const handleRecoverActivateCode = async () => {
		// Elimina el texto de error si es que se presenta anteriormente
		clearErrors('activationCode');

		let registeredEmail = email;
		if (!registeredEmail) {
			registeredEmail = (await trigger('email')) && getValues('email');
		}

		if (registeredEmail) {
			setShowLoading(true);
			try {
				await middleware.user.recoverActivationCode(registeredEmail);
				notify.showSuccessNotify('¡Código enviado!');
			} catch (error) {
				logger.exception(error, `handleRecoverActivateCode: ${error}`);
				notify.showErrorNotify(error);
			}
			setShowLoading(false);
		}
	};

	const handleActivateUserAccount = async (data: IActivateInfo) => {
		logger.info('handleActivateUserAccount', data);
		setShowLoading(true);
		try {
			await middleware.user.activateAccount(email || data.email, data.activationCode!);
			setActivatedAccount(true);
		} catch (error) {
			logger.exception(error, `handleActivateUserAccount: ${error}`);
			notify.showErrorNotify(error);
		}

		setShowLoading(false);
	};

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	return (
		<IonPage>
			<HeaderApp goBackTo={!activatedAccount ? '/signup' : ''} />
			<IonContent className="ion-text-center ion-padding-horizontal" fullscreen>
				{notify.notifyProps.show && <ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />}
				<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Cargando...'} />
				{activatedAccount ? (
					<IonGrid>
						<IonRow className="ion-justify-content-center">
							<IonCol size="12">
								<IonGrid>
									<IonIcon color="success" className="pd-page-title-icon" icon={checkmarkCircleOutline} />
								</IonGrid>
							</IonCol>
							<IonCol size="10">
								<IonTitle size="small">
									<span id="result-message">¡Cuenta activada correctamente!</span>
								</IonTitle>
							</IonCol>
						</IonRow>
						<IonRow className="ion-justify-content-center ion-padding">
							<IonCol>
								<IonButton
									id="btn-account-activated"
									className="pd-button-primary"
									onClick={() => handleRouterLink(`/welcome`)}
								>
									Continuar
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				) : (
					<IonGrid>
						<IonRow>
							<IonCol size="12">
								<IonIcon className="pd-page-title-icon" icon={personCircleOutline} />
							</IonCol>
							<IonCol size="12">
								<h5>
									<b>Ingresa tu código de activación</b>
								</h5>
							</IonCol>
							<IonCol size="12" className="ion-padding">
								{email ? (
									<IonTitle size="small">
										Para verificar tu email, hemos enviado un código a <b>{email}</b>
									</IonTitle>
								) : (
									<IonTitle size="small">Introduce tu email y el código que hemos enviado.</IonTitle>
								)}
							</IonCol>
						</IonRow>

						<IonList className="ion-padding">
							<form onSubmit={handleSubmit(handleActivateUserAccount)}>
								{!email && (
									<FormInput
										label="Correo electrónico"
										name="email"
										control={control}
										errors={errors}
										rules={{
											required: '¡Debe ingresar un email válido!',
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: '¡Email inválido!',
											},
										}}
									/>
								)}

								<FormInput
									label="Código de activación"
									name="activationCode"
									control={control}
									errors={errors}
									type="number"
									rules={{
										pattern: {
											value: /^[0-9]+$/,
											message: '¡Debe ingresar solo números!',
										},
										required: '¡Debe ingresar un código!',
										minLength: {
											value: 6,
											message: '¡Código debe tener 6 caracteres!',
										},
										maxLength: {
											value: 6,
											message: '¡Código debe tener 6 caracteres!',
										},
									}}
								/>

								<IonRow className="ion-padding ion-justify-content-center">
									<IonCol sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="10" sizeXl="12">
										<IonButton type="submit" className="pd-button-primary">
											Activar cuenta
										</IonButton>

										<IonButton className="pd-button-outline" onClick={handleRecoverActivateCode}>
											Volver a enviar código
										</IonButton>
									</IonCol>
								</IonRow>
							</form>
						</IonList>
					</IonGrid>
				)}
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('ActivateAccountPage', 'component')(ActivateAccountPage);
