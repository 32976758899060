import { UseIonHeaderCollapse, useIonHeaderCollapse } from '@codesyntax/ionic-react-header-collapse';
import {
	IonButton,
	IonCol,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg,
	IonRow,
	IonSearchbar,
	IonText,
	IonToolbar,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';
import searchBarAvatar from './../assets/searchbarAvatar.png';

export const HeaderApp: React.FC<{
	subTitle?: string;
	title?: string;
	background?: string;
	goBackTo?: string;
	searchBar?: boolean;
	categoryStyle?: boolean;
	categoryAvatar?: string;
	onBack?: () => void;
	searchText?: string;
	onSearchText?: (text: string) => void;
	autoHide?: boolean;
}> = ({
	subTitle,
	title,
	background = '',
	goBackTo,
	searchBar,
	categoryStyle,
	categoryAvatar,
	onBack,
	onSearchText,
	searchText,
	autoHide = true
}) => {	
	/** 
	 * Easy to use hook to handle collapse effect on scroll for IonHeader component in React Ionic
	 * Link: https://github.com/codesyntax/ionic-react-header-collapse
	 */	
	const history = useHistory();
	const { ref } = useIonHeaderCollapse({} as UseIonHeaderCollapse);

	const handleBackButton = () => {
		if (goBackTo) history.replace(goBackTo, { direction: 'none' });
		if (onBack) onBack();
	};

	const handleSearchText = (text: string) => {
		onSearchText && onSearchText(text);
	};

	return (
		<>
			<IonHeader
				ref={autoHide ? ref : null}
				className={`ion-no-border ${background === 'default' && 'pd-header'}`}
				style={{ background: `${background !== 'default' && background}` }}
			>
				<IonToolbar>
					<IonGrid>
						<IonRow className="ion-text-start ion-align-items-center">
							{(goBackTo || onBack) && (
								<IonCol sizeXs="1" sizeSm="auto">
									<IonButton
										id="btn-back"
										className={`ion-no-margin ion-no-padding ${background ? 'pd-back-button' : 'pd-back-button-contrast'
										}`}
										onClick={handleBackButton}
									>
										<IonIcon icon={chevronBackOutline} />
									</IonButton>
								</IonCol>
							)}

							<IonCol size={goBackTo || onBack ? '11' : '12'}>
								{title ? (
									subTitle ? (
										<IonRow>
											<IonCol size="12">
												<span id="sub-title" className={`pd-header-subtitle${background ? '' : '-contrast'}`}>
													{subTitle}
												</span>
											</IonCol>
											<IonCol size="12">
												<span
													id="title"
													className={`pd-header-title${background ? '' : '-contrast'} ${categoryStyle ? 'pd-header-category-title' : ''}`}
												>
													{title}
												</span>
											</IonCol>
										</IonRow>
									) : (
										<IonRow className="ion-align-items-center">
											<IonCol sizeXl="8" sizeLg="7" sizeMd="7" sizeSm="12" sizeXs="12">
												<IonText>
													<span
														id="title"
														className={`pd-header-title${background ? '' : '-contrast'} ${categoryStyle ? 'pd-header-category-title': ''}`}
													>
														{title}
													</span>
												</IonText>
											</IonCol>
											{searchBar && (
												<>
													<IonCol>
														<IonSearchbar
															id="searchbar"
															mode="md" // clear-icon problems in "ios" mode
															showCancelButton="focus"
															onIonCancel={() => handleSearchText('')}
															className="ion-text-start pd-searchbar ion-no-padding"
															value={searchText}
															placeholder="Buscar..."
															onIonChange={(e: CustomEvent) => handleSearchText(e.detail.value)}
														/>
													</IonCol>
													<IonImg src={searchBarAvatar} className="pd-searchbar-avatar" />
												</>
											)}
										</IonRow>
									)
								) : (
									subTitle && (
										<IonRow>
											<IonCol>
												<span id="sub-title" className={`pd-header-subtitle${background ? '' : '-contrast'}`}>
													{subTitle}
												</span>
											</IonCol>
										</IonRow>
									)
								)}
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonToolbar>
			</IonHeader>
			{categoryStyle && (
				<>
					<div className="pd-header-wave">
						{/* created in https://smooth.ie/blogs/news/svg-wavey-transitions-between-sections */}
						<svg viewBox="0 0 500 250" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
							<path
								d="M0.00,49.98 C150.00,250.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
								style={{ fill: background, stroke: 'transparent' }}
							/>
						</svg>
					</div>
					<IonImg src={categoryAvatar} className="pd-category-avatar" />
				</>
			)}
		</>
	);
};
