import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Logger } from "../utils/logging";

const logger = new Logger('RedirectPage');

const RedirectPage: React.FC<{ path: string }> = ({ path }) => {
	const history = useHistory();

	useEffect(() => {
		logger.info('Path changed', path);

		let newPath = path;

		if (['/', '/my-account/google'].includes(newPath)) {
			newPath = '/index';
		}

		history.replace(newPath, { direction: 'none' });
	}, [history, path]);

	return <></>;
};

export default RedirectPage;
