// Reactjs
// Ionic
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';
import { AppLauncher, AppLauncherOptions } from '@ionic-native/app-launcher';
import {
	IonButton,
	IonCol,
	IonContent,
	IonFooter,
	IonIcon,
	IonPage,
	IonRow,
	IonText,
	IonToolbar,
	isPlatform,
	useIonModal,
} from '@ionic/react';
import { fabric } from 'fabric';
import Hammer from 'hammerjs';
import dayjs from 'dayjs';
import {
	arrowRedoCircle,
	arrowUndoCircle,
	brush,
	caretBackOutline,
	caretForwardOutline,
	closeOutline,
} from 'ionicons/icons';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
// Otras librerias
import { useHistory, useParams } from 'react-router-dom';
import { BookPageSelector } from '../components/BookPageSelector';
import { HeaderApp } from '../components/HeaderApp';
import { ColorModel, PencilColorButton } from '../components/PencilColorButton';
import PencilSizeButton from '../components/PencilSizeButton';
import { IonFullscreenPage } from '../helpers/Fullscreen';
import { ToastApp } from '../components/ToastApp';
// Hooks
import useNotify from '../hooks/useNotify';
import useWindowSize from '../hooks/useWindowSize';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { AssetsMiddleware } from '../middleware/AssetsMiddleware';
import { PageStrokesMiddleware } from '../middleware/StrokesMiddleware';
import { IAcquisitionModel } from '../models/IAcquisitionModel';
import { IAuthorizationModel } from '../models/IAuthorizationModel';
import { IBookModel, IPageModel } from '../models/IBookModel';
import { IProfileModel } from '../models/IProfileModel';
import { IUserModel } from '../models/IUserModel';
import { Config } from '../utils/config';
import { Logger } from '../utils/logging';
import { LRUCache } from '../utils/LRUCache';

import { TextToSpeech } from '@ionic-native/text-to-speech';

const logger = new Logger('BookPage');

// Estados
enum Mode {
	MOVE = 1,
	DRAW,
}

// Colores
const colors: ColorModel[] = [
	new ColorModel('pen-black', '#000000'),
	new ColorModel('pen-purple', '#800080'),
	new ColorModel('pen-red', '#FF0000'),
	new ColorModel('pen-pink', '#FFC0CB'),
	new ColorModel('pen-orange', '#FFA500'),
	new ColorModel('pen-yellow', '#FFFF00'),
	new ColorModel('pen-white', '#FFFFFF'),
	new ColorModel('pen-green', '#7CFC00'),
	new ColorModel('pen-darkgreen', '#008000'),
	new ColorModel('pen-blue', '#00BFFF'),
	new ColorModel('pen-darkblue', '#0000FF'),
	new ColorModel('pen-brown', '#D2691E'),
];

/**
 * Helper class to manage the zoom & pan state
 */
class ZoomPan {
	// State at start of zoom/pan event
	private x0 = 0;
	private y0 = 0;
	private tx0 = 0;
	private ty0 = 0;
	private z0 = 0;

	constructor(
		private canvas: fabric.Canvas,
		private tx: number = 0, // translation, in page coordinates (mm)
		private ty: number = 0,
		private zi: number = 1, // initial zoom so that z=1 fits page width
		private z: number = 1,
		private minz: number = 1, // minimum allowable zoom value
		private maxz: number = 8, // minimum allowable zoom value
		private vw: number = 0, // viewport
		private vh: number = 0,
		private pw: number = 0, // page
		private ph: number = 0
	) { }

	toCanvas() {
		const zt = this.zi * this.z;
		this.canvas.setZoom(zt);
		this.canvas.absolutePan(new fabric.Point(this.tx * zt, this.ty * zt));
		this.canvas.renderAll();
	}

	/**
	 * Updates for a resized viewport
	 * @param vw Viewport width
	 * @param vh Viewport height
	 * @param pw Page width
	 * @param ph Page height
	 */
	resize(vw: number, vh: number, pw: number, ph: number) {
		[this.vw, this.vh, this.pw, this.ph] = [vw, vh, pw, ph];

		const hzoom = vw / pw;
		const vzoom = vh / ph;

		const min = Math.min(hzoom, vzoom) / hzoom;

		this.z = Math.max(this.z, min);
		this.zi = hzoom;
		this.minz = min;

		this.clamp();
		this.toCanvas();
	}

	panStart(x: number, y: number) {
		[this.x0, this.y0] = [x, y];
		[this.tx0, this.ty0] = [this.tx, this.ty];
	}

	panMove(x: number, y: number) {
		const zt = this.z * this.zi;
		this.tx = this.tx0 - (x - this.x0) / zt;
		this.ty = this.ty0 - (y - this.y0) / zt;

		this.clamp();
		this.toCanvas();
	}

	zoomStart(x: number, y: number) {
		[this.x0, this.y0] = [x, y];
		[this.tx0, this.ty0] = [this.tx, this.ty];
		this.z0 = this.z;
	}

	zoomMove(k: number, x: number, y: number) {
		const zt = this.z0 * this.zi;

		this.z = Math.max(this.minz, Math.min(k * this.z0, this.maxz));
		const ck = this.z / this.z0; // Clamped k

		this.tx = this.tx0 + (x / zt) * (1 - 1 / ck) - (x - this.x0) / zt;
		this.ty = this.ty0 + (y / zt) * (1 - 1 / ck) - (y - this.y0) / zt;

		this.clamp();
		this.toCanvas();
	}

	/**
	 * Zooms in at a specified center.
	 * Shortcut for zoomStart + zoomMove for discrete zoom events (eg. wheel)
	 * @param k Zoom amount
	 * @param x Center X (pixels)
	 * @param y Center Y (pixels)
	 */
	zoomAt(k: number, x: number, y: number) {
		this.zoomStart(x, y);
		this.zoomMove(k, x, y);
	}

	/**
	 * Corrects the parameters so that when we are zoomed out,
	 * the page appears centered in the canvas
	 */
	private clamp() {
		const zt = this.z * this.zi;

		// Viewport size in page coordinates, a.k.a Visible Page Size
		const vpw = this.vw / zt;
		const vph = this.vh / zt;

		if (vpw > this.pw) this.tx = -(vpw - this.pw) / 2;
		else this.tx = Math.max(0, Math.min(this.tx, this.pw - vpw));

		if (vph > this.ph) this.ty = -(vph - this.ph) / 2;
		else this.ty = Math.max(0, Math.min(this.ty, this.ph - vph));
	}
}

class FabricImageCache extends LRUCache<fabric.Image> {
	constructor(private assetsMW: AssetsMiddleware, private bookWidthMM: number) {
		super(10);
	}

	async fetch(url: string): Promise<fabric.Image | null> {
		logger.verbose('FabricImageCache.fetch', url);
		const img = await this.assetsMW.getImage(url);
		if (img) {
			logger.verbose('    FabricImageCache.fetch - got decrypted image');

			return new Promise((resolve, reject) => {
				logger.verbose('    FabricImageCache.fetch - loading through Fabric');
				fabric.Image.fromURL(img, (img) => {
					img.scaleToWidth(this.bookWidthMM);
					logger.verbose('    FabricImageCache.fetch - fromURL');
					resolve(img);
				});
			});
		} else {
			return null;
		}
	}
}

// Modals
const DesktopModal: React.FC<{
	onDismiss: () => void;
}> = ({ onDismiss }) => {
	return (
		<IonPage>
			<IonContent className="ion-text-center">
				<IonRow className="ion-padding">
					<IonCol>
						<h4>PleIQ</h4>
					</IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonText>Esta caractéristica sólo se puede usar con el celular</IonText>
					</IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonButton className="pd-button-primary" onClick={() => onDismiss()}>
							<IonIcon icon={closeOutline} slot="start" />
							<span>Cancelar</span>
						</IonButton>
					</IonCol>
				</IonRow>
			</IonContent>
		</IonPage>
	);
};

const openModalLaunchPleiQApp = (): Promise<boolean> => {
	return new Promise((resolve) => {
		let rememberResponse = false;
		const alert = document.createElement('ion-alert');
		alert.header = 'Esta actividad lo llevará a la App de PleiQ';
		alert.message = '¿Desea continuar?';
		alert.buttons = [
			{
				text: '¡No, me quedo!',
				handler: () => {
					console.log('~ rememberResponse', rememberResponse);
					if (rememberResponse) localStorage.setItem('usePleiQApp', 'false');
					resolve(false);
				},
			},
			{
				text: '¡Si, vamos!',
				handler: () => {
					console.log('~ rememberResponse', rememberResponse);
					if (rememberResponse) localStorage.setItem('usePleiQApp', 'true');
					resolve(true);
				},
			},
		];
		alert.inputs = [
			{
				type: 'checkbox',
				label: 'Recordar mi respuesta',
				handler: (e) => (rememberResponse = e.checked!),
			},
		];

		alert.onDidDismiss().then(() => resolve(false));

		document.body.appendChild(alert);
		alert.present();
	});
};

const PleiqNotInstalledModal: React.FC<{
	onDismiss: () => void;
}> = ({ onDismiss }) => {
	return (
		<IonPage>
			<IonContent className="ion-text-center">
				<IonRow className="ion-padding">
					<IonCol>
						<h4>PleIQ no instalado</h4>
					</IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonText>Debe instalar Pleiq para utilizar esta característica.</IonText>
					</IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonButton className="pd-button-primary" onClick={() => onDismiss()}>
							<IonIcon icon={closeOutline} slot="start" />
							<span>Cancelar</span>
						</IonButton>
					</IonCol>
				</IonRow>
			</IonContent>
		</IonPage>
	);
};

// BookPage
const BookPage: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const bookId: number = +useParams<{ bookId: string }>().bookId;
	const authorizationId: number = +useParams<{ authorizationId: string }>().authorizationId;

	// Book
	const [book, setBook] = useState<IBookModel | null>();
	const [grantValid, setGrantValid] = useState<boolean>(false);

	// Canvas
	const [canvasState, setCanvasState] = useState<fabric.Canvas>();
	const [windowWidth, windowHeight] = useWindowSize();
	const [fabricImage, setFabricImage] = useState<fabric.Image | null>();
	const [fabricStrokes, setFabricStrokes] = useState<fabric.Path[] | null>();
	const [fabricAreas, setFabricAreas] = useState<fabric.Rect[] | null>();

	const zoomPanRef = useRef<ZoomPan>();

	const hammerRef = useRef<HammerManager>();

	// Referencias elementos HTML
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const ionContentRef = useRef<HTMLIonContentElement>(null);

	// Mode & pencil options
	const modeRef = useRef<Mode>();
	const [modeState, setModeState] = useState<Mode>();
	const [pencilSizeState, setPencilSizeState] = useState<number>(1);
	const [pencilColorState, setPencilColorState] = useState<ColorModel>(colors[0]);
	const palmRejectionRef = useRef<boolean>();

	// Page
	const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<IPageModel | null>(null);
	const [hasNextPage, setHasNextPage] = useState<boolean>();
	const [hasPreviousPage, setHasPreviousPage] = useState<boolean>();

	const strokesMWRef = useRef<PageStrokesMiddleware>();
	const [assetsCache, setAssetsCache] = useState<FabricImageCache>();

	const [canUndo, setCanUndo] = useState<boolean>();
	const [canRedo, setCanRedo] = useState<boolean>();
	const [canEdit, setCanEdit] = useState<boolean>(false);

	const [showPageSelector, setShowPageSelector] = useState<boolean>(false);
	const history = useHistory();

	const [profile, setProfile] = useState<IProfileModel>();
	const [currentUser, setCurrentUser] = useState<IUserModel | null>();
	const [authorization, setAuthorization] = useState<IAuthorizationModel>();
	const [acquisition, setAcquisition] = useState<IAcquisitionModel>();

	const notify = useNotify();

	// Modals
	// PleIQ Not Installed
	const handleDismissPleiqNotInstalledModal = () => {
		dismissPleiqNotInstalledModal();
	};
	const [presentPleiqNotInstalledModal, dismissPleiqNotInstalledModal] = useIonModal(PleiqNotInstalledModal, {
		onDismiss: handleDismissPleiqNotInstalledModal,
	});

	const handleDismissDesktopModal = () => {
		dismissDesktopModal();
	};
	const [presentDesktopModal, dismissDesktopModal] = useIonModal(DesktopModal, {
		onDismiss: handleDismissDesktopModal,
	});

	useEffect(() => {
		const subs = [
			middleware.user.currentUser$.subscribe(setCurrentUser),
			middleware.authorizations.all$.subscribe((authorizations: any) => {
				const authorization = authorizations.find(
					(authorization: IAuthorizationModel) => authorization.id === authorizationId
				);
				setAuthorization(authorization);
			}),
			middleware.profiles.all$.subscribe((profs: any) => {
				const profile = profs.find((profile: IProfileModel) => profile.id === authorization?.profileId);
				console.log('profile', profile);
				setProfile(profile);
			}),
			middleware.acquisitions.all$.subscribe((acquisitions: any) => {
				const acquisition = acquisitions.find(
					(acquisition: IAcquisitionModel) => acquisition.id === authorization?.bookAcquisitionId
				);
				setAcquisition(acquisition);
				const acqExpired = dayjs() >= dayjs(acquisition?.expirationDate);
				setCanEdit(!acqExpired);
			}),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [
		bookId,
		authorizationId,
		authorization,
		acquisition,
		middleware.acquisitions,
		middleware.authorizations,
		middleware.profiles,
		middleware.user,
	]);

	// Efecto que sucede al iniciarse el libro
	useEffect(() => {
		logger.verbose('Init');
		const canvasContainerElement = ionContentRef.current!;
		const canvas = new fabric.Canvas(canvasRef.current!);
		canvas.selection = false;
		canvas.skipTargetFind = true;
		canvas.stateful = false;
		canvas.isDrawingMode = true;

		canvas.on('path:created', (opt: any) => {
			const svg = opt.path.toSVG();
			if (strokesMWRef.current) strokesMWRef.current.addStroke(svg);
		});

		setCanvasState(canvas);
		setModeState(Mode.MOVE);

		zoomPanRef.current = new ZoomPan(canvas);

		// Lógica para hacer zoom
		canvas.on('mouse:wheel', (opt) => {
			const event = opt.e as WheelEvent;

			let delta;
			switch (event.deltaMode) {
				case WheelEvent.DOM_DELTA_PIXEL:
					delta = event.deltaY / 20;
					break;
				case WheelEvent.DOM_DELTA_LINE:
					delta = event.deltaY;
					break;
				case WheelEvent.DOM_DELTA_PAGE:
					delta = event.deltaY;
					break;
				default:
					delta = Math.sign(event.deltaY);
					break;
			}

			const k = Math.pow(1.05, -delta);
			zoomPanRef.current!.zoomAt(k, event.offsetX, event.offsetY);
			canvas.renderAll();
		});

		hammerRef.current = new Hammer.Manager(canvasContainerElement);
		const mc = hammerRef.current;

		mc.add(new Hammer.Pan({ enable: true }));
		mc.add(new Hammer.Pinch({ enable: true }));
		mc.add(new Hammer.Press({ enable: true }));

		mc.on('hammer.input', function (event) {
			const pen = (event.srcEvent as any).pointerType === 'pen';
			let drawing: boolean;
			switch (modeRef.current) {
				case Mode.DRAW:
					drawing = true;
					break;
				case Mode.MOVE:
					drawing = !!palmRejectionRef.current && pen;
					break;
				default:
					throw new Error(`Mode not handled: ${modeRef.current}`);
			}
			canvas.isDrawingMode = drawing;
			if (drawing) mc.stop(true);
		});

		mc.on('panstart', function (event) {
			const pen = (event.srcEvent as any).pointerType === 'pen';
			if (pen && modeRef.current === Mode.MOVE && palmRejectionRef.current) {
				mc.stop(true);
			} else {
				zoomPanRef.current!.panStart(event.center.x, event.center.y);
			}
		});
		mc.on('panmove', function (event) {
			const pen = (event.srcEvent as any).pointerType === 'pen';
			if (pen && modeRef.current === Mode.MOVE && palmRejectionRef.current) {
				mc.stop(true);
			} else {
				zoomPanRef.current!.panMove(event.center.x, event.center.y);
			}
		});
		mc.on('pinchstart', function (event) {
			zoomPanRef.current!.zoomStart(event.center.x, event.center.y);
		});
		mc.on('pinchmove', function (event) {
			zoomPanRef.current!.zoomMove(event.scale, event.center.x, event.center.y);
		});
	}, []);

	useEffect(() => {
		const mc = hammerRef.current!;

		const areaEnabled = Config.PLEIQ_INTEGRATION || Config.VOICE;
		if (areaEnabled && canvasState && mc) {
			mc.on('press', function (event) {
				const objects = canvasState.getObjects()?.filter((obj) => obj.data);
				objects.forEach((obj) => {
					// Its obtains point where it was presses on the screen, then
					// it is verified if it is in one of the areas related to pleiq
					const contains = canvasState.containsPoint(event.srcEvent, obj);

					if (contains) {
						// It only opens if the application is being used from the cell phone
						const type = obj.data.type;

						if (type === 'pleiq' && Config.PLEIQ_INTEGRATION) {
							const isCapacitor = isPlatform('capacitor');
							if (isCapacitor) {
								// trigger our action of obj.data.id
								const options: AppLauncherOptions = {};

								const email = currentUser?.email!;
								const name = [currentUser?.firstName, currentUser?.lastName].filter((text) => text).join('_')!;
								const bookPleIQ = book!.pleiqSlug;
								const markerPleIQ = obj.data.data;
								const code = acquisition?.code?.code!;
								const child = profile!.name?.replace(/\s/g, '_');

								options.uri = `pleiq://app?entry=openbook&&email=${email}&&name=${name}&&book=${bookPleIQ}&&code=${code}&&marker=${markerPleIQ}&&child=${child}`;

								// Check if pleiq is installed
								AppLauncher.canLaunch(options)
									.then(async () => {
										//AppLauncher.launch(options);
										const usePleiQApp = localStorage.getItem('usePleiQApp'); // Mejorar
										if (usePleiQApp === 'false') return;
										if (usePleiQApp === 'true') window.open(options.uri, '_system');
										else {
											const canLaunchPleiQApp = await openModalLaunchPleiQApp();
											if (canLaunchPleiQApp) window.open(options.uri, '_system');
										}
									})
									.catch((error: any) => {
										console.log('~ error', error);
										// Pleiq is not available
										presentPleiqNotInstalledModal();
									});
							} else {
								// Desktop app
								presentDesktopModal();
							}
						} else if (type === 'voice' && Config.VOICE) {
							const isCapacitor = isPlatform('capacitor');
							if (isCapacitor) {
								TextToSpeech.speak({
									text: obj.data.data,
									locale: 'es-CL',
								})
									.then((data) => {
										logger.info(data);
									})
									.catch((err) => {
										logger.error(err);
									});
							} else {
								const u = new SpeechSynthesisUtterance();
								u.text = obj.data.data;
								u.lang = 'es-CL';
								if (speechSynthesis.speaking) {
									speechSynthesis.cancel();
								}
								speechSynthesis.speak(u);
							}
						}
					}
				});
			});
		}

		return () => {
			mc.off('press');
		};
	}, [book, currentUser, profile, acquisition, canvasState, presentDesktopModal, presentPleiqNotInstalledModal]);

	// Grant logic
	useEffect(() => {
		logger.verbose('BookPage - subscribing to isValidForAuth$');
		if (authorizationId !== 0) {
			const subs = [
				middleware.grants.isValidForAuth$(authorizationId).subscribe(async (valid) => {
					logger.info('BookPage - isValidForAuth$', valid);
					if (valid) {
						setGrantValid(true);
					} else {
						try {
							await middleware.grants.requestGrant(authorizationId);
							setGrantValid(true);
						} catch (error) {
							setGrantValid(false);
							history.replace(`/books/withoutgrant/${bookId}/${authorizationId}`, { direction: 'none' });
						}
					}
				}),
			];

			return () => {
				subs.forEach((s) => s.unsubscribe());
			};
		} else {
			setGrantValid(false);
		}
	}, [bookId, authorizationId, history, middleware.grants]);

	useEffect(() => {
		middleware.grants.updateGrants();
	}, [middleware.grants, currentPage]);

	// Track the palm rejection setting
	useEffect(() => {
		const subs = [middleware.localConfig.palmRejection$.subscribe((value) => (palmRejectionRef.current = value))];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [middleware.localConfig.palmRejection$]);

	// Carga el libro cuando el bookId cambia
	useEffect(() => {
		logger.info('Load book');
		const subs = [
			middleware.books.byId$(bookId).subscribe((book) => {
				if (book) {
					setAssetsCache(new FabricImageCache(middleware.assets, book.widthMM));
					setBook(book);
					logger.info('Got book', book);
				} else {
					setBook(null);
					logger.info('Book is null');
				}
			}),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [bookId, middleware.books, middleware.assets]);

	// Prefetch all pages
	useEffect(() => {
		if (book) {
			middleware.books.ensureCached(book);
		}
	}, [book, middleware.books]);

	// Cambiar a siguiente página
	const onNextPage = () => {
		logger.info('onNextPage', currentPageNumber, book?.pages.length);
		if (book?.pages && currentPageNumber < book.pages.length) {
			setCurrentPageNumber(currentPageNumber + 1);
		}
	};

	// Cambiar a página anterior
	const onPreviousPage = () => {
		logger.info('onPreviousPage', currentPageNumber, book?.pages.length);
		if (book?.pages && currentPageNumber > 1) {
			setCurrentPageNumber(currentPageNumber - 1);
		}
	};

	// Update has[Next/Previous]Page state
	useEffect(() => {
		setHasNextPage(book?.pages && currentPageNumber < book.pages.length);
		setHasPreviousPage(book?.pages && currentPageNumber > 1);
	}, [book?.pages, currentPageNumber]);

	// Activar o desactivar modo pintado
	const onToggleDrawMode = () => {
		// Si el código del libro ha expirado el modo será siempre Mode.MOVE
		setModeState(!canEdit ? Mode.MOVE : modeState === Mode.DRAW ? Mode.MOVE : Mode.DRAW)
	};

	// Cambiar tamaño pincel
	const onChangeSize = (size: number) => {
		setPencilSizeState(size);
	};

	// Cambiar color pincel
	const onChangeColor = (color: ColorModel) => {
		setPencilColorState(color);
	};

	// Efecto que sucede cuando hay un cambio en el pincel
	useEffect(() => {
		logger.info('useEffect [canvasState, pencilSizeState, pencilColorState]');
		if (canvasState) {
			canvasState.freeDrawingBrush.width = pencilSizeState;
			canvasState.freeDrawingBrush.color = pencilColorState.color;
		}
	}, [canvasState, pencilSizeState, pencilColorState]);

	useEffect(() => {
		modeRef.current = modeState;
	}, [modeState]);

	// Borrar último trazado
	const onUndo = () => {
		if (book && strokesMWRef.current) strokesMWRef.current.undo();
	};

	const onRedo = () => {
		if (book && strokesMWRef.current) strokesMWRef.current.redo();
	};

	const renderPage = useCallback(async () => {
		if (!canvasState) return;

		logger.info('renderPage');

		canvasState.clear();

		if (fabricImage) {
			canvasState.setBackgroundImage(fabricImage, () => {
				canvasState.renderAll();
			});
			logger.verbose('    renderPage - fabricImage');
		}

		if (fabricAreas) {
			fabricAreas.forEach((area) => {
				canvasState.add(area);
			});
			canvasState.renderAll();
			logger.verbose('    renderPage - fabricAreas');
		}

		if (fabricStrokes) {
			fabricStrokes.forEach((path) => canvasState.add(path));
			canvasState.renderAll();
			logger.verbose('    renderPage - fabricStrokes');
		}
	}, [canvasState, fabricImage, fabricStrokes, fabricAreas]);

	const resizeCanvas = useCallback(
		(canvas: fabric.Canvas) => {
			logger.verbose('resizeCanvas');
			let w: number, h: number;

			const ionContent = ionContentRef.current as HTMLElement;

			// Keep trying to setup canvas size until container has
			// a non-empty size.
			// This is hackish... we cannot get the container's size
			// before the DOM is rendered, but I don't know how to hook into that
			// Tried useLayoutEffect but it didn't work
			if (!ionContent || ionContent.offsetWidth === 0 || ionContent.offsetHeight === 0) {
				logger.info('Container size not available, retrying later');

				// Set the canvas size to window size in the mean time
				// At least the width should not change
				w = window.innerWidth;
				h = window.innerHeight;

				if (grantValid)
					window.setTimeout(() => {
						resizeCanvas(canvas);
					}, 1);
			} else {
				w = ionContent.offsetWidth;
				h = ionContent.offsetHeight;
			}

			//logger.info("New canvas size: ", w, h);
			canvas.setWidth(w);
			canvas.setHeight(h);
			zoomPanRef.current!.resize(w, h, book!.widthMM, book!.heightMM);
		},
		[book, grantValid]
	);

	// Efecto que sucede cuando hay un cambio de dimensiones pantalla en el dispositivo
	useLayoutEffect(() => {
		if (canvasState && book) resizeCanvas(canvasState);
	}, [canvasState, windowWidth, windowHeight, book, resizeCanvas]);

	// Hace el render cuando se actualizan la imagen o los strokes
	useEffect(() => {
		logger.info('useEffect [canvasState, fabricAreas, fabricStrokes, fabricImage]');
		renderPage();
	}, [canvasState, fabricStrokes, fabricImage, renderPage]);

	const loadPageImage = useCallback(async () => {
		if (book && assetsCache && currentPage) {
			logger.info('loadPageImage');
			const img = await assetsCache.get(`${book.url}/${currentPage.image}`);
			logger.verbose('    loadPageImage - img');
			setFabricImage(img);
		} else {
			setFabricImage(null);
		}
	}, [book, assetsCache, currentPage]);

	// Carga la página cuando hay un cambio de nº de página o de libro
	useEffect(() => {
		loadPageImage();
	}, [loadPageImage]);

	// Carga la página cuando hay un cambio de nº de página o de libro
	useEffect(() => {
		setCurrentPage(book?.pages?.[currentPageNumber - 1] || null);
	}, [currentPageNumber, book]);

	// Carga los strokes cuando hay un cambio de nº de página o de libro
	useEffect(() => {
		if (currentPage && authorizationId) {
			const strokesMW = middleware.strokes.forPage(authorizationId, currentPage.id);
			strokesMWRef.current = strokesMW;

			const subs = [
				strokesMW.strokes$.subscribe((strokes) => {
					setFabricStrokes(
						strokes
							.map((stroke) => {
								if (typeof stroke !== 'string') return null;
								let result: any = null;
								// In the current implementation of Fabric, the callback is called
								// synchronously, so this is ugly, but it works.
								fabric.loadSVGFromString(stroke, (results, options) => {
									result = results[0];
								});
								return result;
							})
							.filter((d) => d)
					);
				}),
				strokesMW.canUndo$.subscribe(setCanUndo),
				strokesMW.canRedo$.subscribe(setCanRedo),
			];

			return () => {
				subs.forEach((s) => s.unsubscribe());
			};
		} else {
			setFabricStrokes([]);
			return () => { };
		}
	}, [book, currentPage, authorizationId, middleware.strokes]);

	// Carga las areas cuando hay un cambio de nº de página o de libro
	useEffect(() => {
		const areaEnabled = Config.PLEIQ_INTEGRATION || Config.VOICE;
		if (areaEnabled && currentPage && authorizationId) {
			const areas = currentPage?.areas?.map((area) => {
				let result: any = null;

				fabric.loadSVGFromString(area.svg, (objects, options) => {
					const { svg, ...data } = area;

					const object = objects[0];

					object.data = data;
					object.setControlsVisibility({ mtr: false });
					object.objectCaching = false;
					object.lockRotation = true;
					object.hasBorders = false;
					object.noScaleCache = false;
					object.data.svg = object.toSVG();
					if (Config.SHOW_BOOK_AREAS) {
						if (object.data.type === 'pleiq') {
							object.fill = 'rgba(0,255,0,0.2)';
						} else if (object.data.type === 'voice') {
							object.fill = 'rgba(0,0,255,0.2)';
						}
					}

					result = object;
				});
				return result;
			});

			setFabricAreas(areas);
		}
	}, [book, currentPage, authorizationId]);

	const showNotification = () => {
		const message = `¡Código de activación se encuentra vencido, este cuaderno solo se podía usar hasta el ${new Date(acquisition!.expirationDate).toJSON().slice(0, 10).split('-').reverse().join('-')}!`;
		notify.showCustomNotify('danger', message, 5000);
	};

	const disabledButtonStyle = {
		opacity: 0.5,
		pointerEvents: "all"
	};

	return (
		<IonFullscreenPage>
			<HeaderApp
				title={`${book && book!.title}${Config.UI_DEBUG_ENTITY_IDS ? ` [p #${currentPage?.id || '...'}]` : ''}`}
				goBackTo={
					authorizationId && middleware.user.currentUser$.value?.pin
						? `/books/leave/${bookId}/${authorizationId}`
						: `/menu/books/detail/${bookId}`
				}
			/>
			<IonContent className="hidden-overflow" ref={ionContentRef}>
				{notify.notifyProps.show && <ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />}
				<canvas id="main-canvas" ref={canvasRef} />
			</IonContent>
			<IonFooter>
				<IonToolbar style={{ textAlign: 'center' }}>
					{authorizationId ? (
						<>
							<IonButton
								id="btn-draw-mode"
								className="pd-control-page-button"
								size="small"
								onClick={() => !canEdit ? showNotification() : onToggleDrawMode()}
								style={!canEdit ? disabledButtonStyle : {}}
								color={modeState === Mode.DRAW ? 'success' : 'primary'}
							>
								<IonIcon icon={brush} />
							</IonButton>

							<IonButton
								size="small"
								id="btn-undo-stroke"
								className="pd-control-page-button"
								onClick={() => !canEdit ? showNotification() : onUndo()}
								disabled={!canEdit ? false : !canUndo}
								style={!canEdit ? disabledButtonStyle : {}}
							>
								<IonIcon icon={arrowUndoCircle} />
							</IonButton>

							<IonButton
								size="small"
								id="btn-redo-stroke"
								className="pd-control-page-button"
								onClick={() => !canEdit ? showNotification() : onRedo()}
								disabled={!canEdit ? false : !canRedo}
								style={!canEdit ? disabledButtonStyle : {}}
							>
								<IonIcon icon={arrowRedoCircle} />
							</IonButton>

							<IonButton
								id="btn-previous-page"
								size="small"
								className="pd-control-page-button"
								onClick={onPreviousPage}
								disabled={!hasPreviousPage}
							>
								<IonIcon icon={caretBackOutline} />
							</IonButton>

							<IonButton
								id="btn-page-number"
								size="small"
								className="pd-control-page-button"
								color="light"
								onClick={() => setShowPageSelector(true)}
							>
								{currentPageNumber}
							</IonButton>

							<IonButton
								id="btn-next-page"
								size="small"
								className="pd-control-page-button"
								onClick={onNextPage}
								disabled={!hasNextPage}
							>
								<IonIcon icon={caretForwardOutline} />
							</IonButton>

							<PencilSizeButton
								size={pencilSizeState}
								sizeHandler={onChangeSize}
								showAsDisabled={!canEdit}
								onClickDisabledButton={showNotification}
							/>

							<PencilColorButton
								color={pencilColorState}
								colors={colors}
								colorHandler={onChangeColor}
								showAsDisabled={!canEdit}
								onClickDisabledButton={showNotification}
							/>
						</>
					) : (
						<>
							<IonButton
								id="btn-previous-page"
								size="small"
								className="pd-control-page-button"
								onClick={onPreviousPage}
								disabled={!hasPreviousPage}
							>
								<IonIcon icon={caretBackOutline} />
							</IonButton>

							<IonButton
								id="btn-page-number"
								size="small"
								className="pd-control-page-button"
								color="light"
								onClick={() => setShowPageSelector(true)}
							>
								{currentPageNumber}
							</IonButton>

							<IonButton
								id="btn-next-page"
								size="small"
								className="pd-control-page-button"
								onClick={onNextPage}
								disabled={!hasNextPage}
							>
								<IonIcon icon={caretForwardOutline} />
							</IonButton>
						</>
					)}
				</IonToolbar>
				{currentPage && book && (
					<BookPageSelector
						book={book}
						onChangePage={setCurrentPageNumber}
						middleware={middleware}
						currentPage={currentPage}
						showPageSelector={showPageSelector}
						setShowPageSelector={setShowPageSelector}
					/>
				)}
			</IonFooter>
		</IonFullscreenPage>
	);
};

export default withTransaction('BookPage', 'component')(BookPage);
