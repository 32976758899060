import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAcquisitionModel, IDeepAcquisitionModel } from '../models/IAcquisitionModel';
import { IDeepAuthorizationModel } from '../models/IAuthorizationModel';
import { ErrorWrapper } from '../utils/ErrorWrapper';
import { APIRequest } from './APIRequest';
import { AppMiddleware } from './AppMiddleware';
import { PrivateResourceMiddleware } from './PrivateResourceMiddleware';
import { Logger } from '../utils/logging';

const logger = new Logger('AcquisitionsMiddleware');

export class AcquisitionsMiddleware extends PrivateResourceMiddleware<IAcquisitionModel> {
	constructor(private middleware: AppMiddleware) {
		super('acquisitions', '/books/my-acquisitions', middleware.user.currentUser$);
	}

	async activateNewBook(bookId: number, code: string, force: boolean = false): Promise<IAcquisitionModel> {
		let data = { bookId, code, force };
		try {
			const result = await APIRequest.post('/books/activate', data);
			const acq = result.data;
			const book = await this.middleware.books.reloadBook(bookId);
			await this.editLocalData(acq.id, acq);

			// Trigger caching, but do not wait for it to finish
			this.middleware.books.ensureCached(book);

			return acq;
		} catch (error) {
			logger.exception(error, `activateNewBook: ${error}`);
			throw new ErrorWrapper(error);
		}
	}

	async checkBookCode(code: string): Promise<number | null> {
		try {
			let response = await APIRequest.get(`/codes/check?code=${code}`);
			return response.data.bookId;
		} catch (error) {
			logger.exception(error, `Error al validar el código del cuaderno, en checkBookCode: ${error}`);
			throw new ErrorWrapper(error);
		}
	}

	// Returns an observable that emits graphs of profiles,
	// acquisitions and authorizations objects
	getDeepAcqs$(bookId: number): Observable<IDeepAcquisitionModel[]> {
		return combineLatest([
			this.middleware.authorizations.all$,
			this.middleware.profiles.all$,
			this.all$,
			this.middleware.grants.all$,
		]).pipe(
			map(([auths, profiles, acqs, grants]) => {
				if (acqs.length > 0) {
					// 1. Filter acqs and auths that are related to the current book
					const bookAuths = auths.filter((a) => a.bookId === bookId);
					const bookAcqs = acqs.filter((a) => a.bookId === bookId);

					// 2. Build graph
					const profilesMap = new Map(profiles.map((p) => [p.id, p]));
					const authToGrantMap = new Map(grants.map((g) => [g.bookAuthorizationId, g]));

					// 2.1. add profiles and  grants to auths}
					// the grants are updated periodically thus their information is more up to date than the auths information
					const deepAuths = bookAuths.map((a) => ({
						...a,
						profile: profilesMap.get(a.profileId)!,
						currentGrant: authToGrantMap.get(a.id),
						currentGrantId: authToGrantMap.get(a.id)?.id,
					}));

					// 2.2. add auths to acqs
					const deepAcqs = bookAcqs.map((a) => ({ ...a, authorizations: [] as IDeepAuthorizationModel[] }));
					const deepAcqsMap = new Map(deepAcqs.map((a) => [a.id, a]));
					for (let auth of deepAuths) deepAcqsMap.get(auth.bookAcquisitionId)?.authorizations.push(auth);

					return deepAcqs;
				} else {
					return [];
				}
			})
		);
	}
}
