import { SimpleResourceMiddleware } from './SimpleResourceMiddleware';
import { IDBWrapper } from '../utils/IDBWrapper';
import { IResource } from '../models/IResource';
import { Observable } from "rxjs";
import { IUserModel } from "../models/IUserModel";

export class PublicResourceMiddleware<T extends IResource> extends SimpleResourceMiddleware<T> {
	private readonly localDb: IDBWrapper;
	private readonly readyPromise: Promise<void>;

	constructor(
		dbName: string,
		urlFragment: string,
		currentUser$?: Observable<IUserModel | null>
	) {
		super(urlFragment);
		this.localDb = new IDBWrapper(dbName);
		this.readyPromise = new Promise(async (resolve) => {
			await this.dispatchLocalData();
			resolve();
		});

		// If we have the currentUser$ observable,
		// we invalidate the cache when the user changes.
		// The server might return different values of some fields
		// for different users
		// Eg. books only have pages when the user is logged in.
		currentUser$?.subscribe((user) => {
			this.invalidateLocalData();
		});
	}

	public ready() {
		return this.readyPromise;
	}

	get(id: number) {
		return this.localDb.get(id.toString());
	}

	getAll() {
		return this.localDb.getAll();
	}

	put(id: number, data: any) {
		return this.localDb.put(id.toString(), data);
	}

	delete(id: number) {
		return this.localDb.delete(id.toString());
	}
}
