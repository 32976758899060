import {
	IonButton,
	IonChip,
	IonCol,
	IonContent,
	IonFab,
	IonFabButton,
	IonGrid,
	IonIcon,
	IonImg,
	IonLabel,
	IonPage,
	IonRow,
	IonTitle,
} from '@ionic/react';
import { add, closeCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import img from '../assets/welcome.jpg';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IProfileModel } from '../models/IProfileModel';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('ProfilePage');

const ProfilePage: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();
	const acquisitionId: number = +useParams<{ acquisitionId: string }>().acquisitionId;
	const [profiles, setProfiles] = useState<IProfileModel[]>([]);

	useEffect(() => {
		const subs = [
			middleware.profiles.all$.subscribe((profs: any) => {
				logger.info('Got profiles', profs);
				setProfiles(profs);
			}),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [middleware.profiles]);

	const handleDeleteItem = (e: any, profile: any) => {
		e.stopPropagation(); // Evita que se gatille el edit

		middleware.profiles
			.deleteData(profile)
			.then(() => notify.showSuccessNotify('¡Perfil eliminado exitosamente!'))
			.catch((error) => {
				logger.exception(error, `ProfilePage.handleDeleteItem: ${error}`);
				notify.showErrorNotify(error);
			});
	};

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	return (
		<IonPage>
			<HeaderApp goBackTo={acquisitionId ? `/menu/books/profiles/${acquisitionId}` : '/menu/setting'} />
			<IonContent className="ion-padding-horizontal" fullscreen>
				{notify.notifyProps.show && <ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />}
				<IonGrid className="ion-text-center">
					<h2>Perfiles</h2>
					<IonRow className="ion-padding ion-justify-content-center">
						{profiles && profiles.length ? (
							profiles.map((profile: IProfileModel) => {
								return (
									<IonCol key={profile.id} sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="4" sizeXl="2">
										<IonChip
											outline={true}
											className="ion-padding"
											onClick={() =>
												handleRouterLink(
													`/menu/profiles/action/edit/${acquisitionId ? acquisitionId : null}/${profile.id}`
												)
											}
										>
											<IonLabel id={`profile-${profile.id}`}>{profile.name}</IonLabel>
											<IonIcon size="large" icon={closeCircleOutline} onClick={(e) => handleDeleteItem(e, profile)} />
										</IonChip>
									</IonCol>
								);
							})
						) : (
							<>
								<IonCol sizeXs="10" sizeSm="9" sizeMd="8" sizeLg="4" sizeXl="2" className="pd-tutorial-img">
									<IonImg src={img} alt="welcome" />
								</IonCol>

								<IonCol size="12" className="ion-padding">
									<IonTitle size="small">
										¡Aún no has creado perfiles! Agrega perfiles para que tus niñ@s puedan acceder a los cuadernos.
									</IonTitle>
								</IonCol>
							</>
						)}
					</IonRow>

					<IonRow className="ion-padding">
						<IonCol size="12" className="ion-padding">
							<IonFab vertical="center" horizontal="center">
								<IonFabButton>
									<IonIcon
										id="btn-add-profile"
										size="large"
										className="ion-padding"
										onClick={() => handleRouterLink(`/menu/profiles/action/add/${acquisitionId ? acquisitionId : ''}`)}
										icon={add}
									/>
								</IonFabButton>
							</IonFab>
						</IonCol>
					</IonRow>

					<p />

					{acquisitionId > 0 && profiles && profiles.length > 0 && (
						<IonRow className="ion-justify-content-center">
							<IonButton
								className="pd-button-outline"
								onClick={() => handleRouterLink(`/menu/books/profiles/${acquisitionId}`)}
							>
								Continuar
							</IonButton>
						</IonRow>
					)}
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('ProfilePage', 'component')(ProfilePage);
