import { IAuthorizationModel } from '../models/IAuthorizationModel';
import { IProfileModel } from '../models/IProfileModel';
import { AcquisitionsMiddleware } from './AcquisitionsMiddleware';
import { AssetsMiddleware } from './AssetsMiddleware';
import { BooksMiddleware } from './BooksMiddleware';
import { StrokesMiddleware } from './StrokesMiddleware';
import { UserMiddleware } from './UserMiddleware';
import { LocalConfigMiddleware } from './LocalConfigMiddleware';
import { PrivateResourceMiddleware } from './PrivateResourceMiddleware';
import { CategoriesMiddleware } from './CategoriesMiddleware';
import { NetworkStatusMiddleware } from './NetworkStatusMiddleware';
import { GrantsMiddleware } from './GrantsMiddleware';
import { DeviceMiddleware } from "./DeviceMiddleware";
import { Logger } from "../utils/logging";

const logger = new Logger('AppMiddleware');

export class AppMiddleware {
	public readonly networkStatus = new NetworkStatusMiddleware();

	public readonly user = new UserMiddleware();
	public readonly device = new DeviceMiddleware(this);


	// TODO: resolver las urls con filtros ej.: books/?pageSize=500 o my-authorizations que solo trean los 10 primeros registros
	public readonly books = new BooksMiddleware(this);

	public readonly categories = new CategoriesMiddleware(this);

	public readonly profiles = new PrivateResourceMiddleware<IProfileModel>(
		'profiles',
		'/my-profiles',
		this.user.currentUser$
	);

	public readonly acquisitions = new AcquisitionsMiddleware(this);

	public readonly authorizations = new PrivateResourceMiddleware<IAuthorizationModel>(
		'authorizations',
		'/books/my-authorizations',
		this.user.currentUser$
	);

	public readonly grants = new GrantsMiddleware(this);

	public readonly strokes = new StrokesMiddleware(this);

	public readonly localConfig = new LocalConfigMiddleware();

	public readonly assets = new AssetsMiddleware();

	constructor() {
		logger.verbose('Created');
	}

	/**
	 * Waits until this middleware is ready.
	 * Readiness is determined in turn by the readiness of
	 * the sub-middlewares that have a ready function
	 */
	public async ready() {
		await Promise.all([
			this.user.ready(),
			this.networkStatus.ready(),
			this.books.ready(),
			this.categories.ready(),
			this.profiles.ready(),
			this.acquisitions.ready(),
			this.authorizations.ready(),
			this.grants.ready(),
			this.strokes.ready(),
			this.localConfig.ready(),
			this.assets.ready(),
		]);
	}
}
